import { render, staticRenderFns } from "./index.vue?vue&type=template&id=22fd03a4&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=22fd03a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22fd03a4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatContactAvatar: require('/root/repo/components/chat/ChatContactAvatar.vue').default,AccountFranchisorLayout: require('/root/repo/components/account-franchisor/AccountFranchisorLayout.vue').default})
