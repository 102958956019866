//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import DirectoryCard from "@/components/cards/DirectoryCard"
import FormEditRating from "@/components/form/FormEditRating"
import ArrowCodeIcon from "@/components/icons/ArrowCodeIcon"
import MoneyFormat from 'vue-money-format'
import ShareButtons from '@/components/ShareButtons'
import TweetList from '@/components/company/TweetList'
import VerifiedIcon from '@/components/icons/VerifiedIcon'
import CompanyInnovationCurve from '@/components/company/CompanyInnovationCurve'
import CompanyFounder from '@/components/company/CompanyFounder'
import ArrowIcon from '@/components/icons/ArrowIcon'
import storeMixin from '@/components/mixins/storeMixin'
import Loader from "@/components/Loader"
import UserAvatarBlankIcon from "@/assets/icons/user/UserAvatarBlankIcon.svg?inline"
import FranchiseLikeIcon from "@/assets/icons/franchise/FranchiseLikeIcon.svg?inline"
import FranchiseCompareIcon from "@/assets/icons/franchise/FranchiseCompareIcon.svg?inline"
import FranchiseLinkLandingIcon from "@/assets/icons/franchise/FranchiseLinkLandingIcon.svg?inline"
import InfoIcon from '@/assets/icons/InfoIcon.svg?inline'
import hubspotMixin from '@/components/mixins/hubspotMixin'
import VButton from "@/components/VButton"
import BaseModal from "@/components/modals/BaseModal"
import helpers from "@/components/mixins/helpers"
import CloseIcon from '@/assets/icons/cross/CloseIcon.svg?inline'
import CompanyPageLanding from '~/components/CompanyPageLanding'

import ModalSignUpFranchisor from "@/components/modals/ModalSignUpFranchisor"

import {
  LANDING_OLD_LIST,
  LANDING_WEBSITE_PREFIX,
  MODAL_RATING_FRANCHISE_ID,
  MODAL_SIGN_UP_FRANCHISOR_ID,
  USER_TYPE_FRANCHISEE,
  USER_TYPE_FRANCHISOR,
  HUBSPOT_EVENT_TYPES,
} from '~/store/constants'

export default {
  name: 'FranchisePage',
  components: {
    ModalSignUpFranchisor,
    DirectoryCard,
    ArrowCodeIcon,
    FormEditRating,
    BaseModal,
    VButton,
    Loader,
    MoneyFormat,
    ShareButtons,
    TweetList,
    VerifiedIcon,
    CompanyInnovationCurve,
    CompanyFounder,
    ArrowIcon,
    CompanyPageLanding,
    UserAvatarBlankIcon,
    FranchiseLikeIcon,
    FranchiseCompareIcon,
    FranchiseLinkLandingIcon,
    InfoIcon,
    CloseIcon,
  },
  mixins: [
    storeMixin,
    hubspotMixin,
    helpers,
  ],
  async asyncData({$axios, store, route, error}) {
    const slug = route.params.slug
    const strictIndustryMode = false

    let likes
    let franchise = null
    let arrData
    let notFound = false

    await store.dispatch('account/setShowPreloader', true)
    try {
      const franchiseResponse = await $axios.get(
        `/api/v1/business/${route.params.company}/data/latest/`
      )
      arrData = franchiseResponse.data.data[0]

      if (arrData.company_short_description.attribute_value_string === '') {
        arrData.company_short_description.attribute_value_string = null
      }

      const seoTitle = (typeof arrData.seo_meta_title.attribute_value_string === "undefined"
        || arrData.seo_meta_title.attribute_value_string === ''
        || arrData.seo_meta_title.attribute_value_string === null
      )
        ? `${arrData.business_name.attribute_value_string} Franchise Opportunity, Cost & Information`
        : arrData.seo_meta_title.attribute_value_string
      const seoDescription = (typeof arrData.seo_meta_description.attribute_value_string === "undefined"
        || arrData.seo_meta_description.attribute_value_string === ''
        || arrData.seo_meta_description.attribute_value_string === null
      )
        ? `Franchise info - ${arrData.business_name.attribute_value_string}`
        : arrData.seo_meta_description.attribute_value_string

      franchise = {
        id: arrData.franchise_id,
        isVerified: arrData.certified_by_fa.attribute_value_string === '1',
        isPublish: arrData.company_page_published.attribute_value_string === 1,
        business_name: arrData.business_name.attribute_value_string,
        company_page_published: arrData.company_page_published.attribute_value_string,
        certified_by_fa: arrData.certified_by_fa.attribute_value_string,
        company_twitter_username:
          arrData.company_twitter_username.attribute_value_string === ''
            ? '@Franchise1_2_3'
            : arrData.company_twitter_username.attribute_value_string,
        company_stage: arrData.company_stage.attribute_value_string,
        company_description: arrData.company_description.attribute_value_string,
        industry: arrData.industry.attribute_value_string,
        number_of_locations: arrData.number_of_locations.attribute_value_string,
        location: arrData.location.attribute_value_string ?? 'N/A',
        websiteUrl: arrData.public_website_business_permalink.attribute_value_string ?? '',
        membership: arrData.membership.attribute_value_string,
        established: arrData.established.attribute_value_string,
        franchising_since: arrData.franchising_since.attribute_value_string,
        investment_min: arrData.investment.attribute_value_numeric_low,
        investment_max: arrData.investment.attribute_value_numeric_high,
        franchise_fee_min: arrData.franchise_fee.attribute_value_numeric_low,
        franchise_fee_max: arrData.franchise_fee.attribute_value_numeric_high,
        gross_profit:
          arrData.gross_profit.attribute_value_string &&
          Number(arrData.gross_profit.attribute_value_string) > 0
            ? arrData.gross_profit.attribute_value_string
            : '',
        average_revenue:
          arrData.average_revenue.attribute_value_string &&
          Number(arrData.average_revenue.attribute_value_string) > 0
            ? arrData.average_revenue.attribute_value_string
            : '',
        main_royalty_fee: arrData.main_royalty_fee.attribute_value_string,
        main_royalty_fee_percent:
          arrData.main_royalty_fee_percent.attribute_value_string === ''
            ? ''
            : `${arrData.main_royalty_fee_percent.attribute_value_string} %`,
        slug_industry: arrData.slug_industry.attribute_value_string,
        seo_meta_title: seoTitle,
        seo_meta_keywords: arrData.seo_meta_keywords.attribute_value_string ?? '',
        seo_meta_description: seoDescription,
        short_descr:
          arrData.company_short_description.attribute_value_string ??
          arrData.short_descr.attribute_value_string ??
          '',
        video: arrData.video.attribute_value_string ?? '',
        presentation:
          arrData.files.presentation && arrData.files.presentation.url
            ? arrData.files.presentation.url
            : '',
        slug: arrData.slug,
        imageUrl: arrData.logo,
        itemCompanyUrl: `/company-pages/${arrData.slug}`,
        logo: arrData.logo.medium,
        founders: arrData.founders,
        selfRatings: arrData.self_ratings,
        surveyRatings: arrData.survey_ratings,
        like: arrData.like,
        in_comparison: arrData.in_comparison,
        flow_status: arrData.flow_status,
        landing_phone: arrData.landing_phone.attribute_value_string,
        style_color_light: arrData.style_color_light.attribute_value_string,
        style_color_dark: arrData.style_color_dark.attribute_value_string,
        style_color_vibrant: arrData.style_color_vibrant.attribute_value_string,
        style_font_title: arrData.style_font_title.attribute_value_string,
        style_font_paragraph: arrData.style_font_paragraph.attribute_value_string,
        minimum_liquid_assets: arrData.minimum_liquid_assets.attribute_value_string,
        total_net_worth: arrData.total_net_worth.attribute_value_string,
        minimum_credit_score: arrData.minimum_credit_score.attribute_value_string,
        tags: arrData.tags,
        file_fdd:
          arrData.files.fdd && arrData.files.fdd.url
            ? arrData.files.fdd.url
            : '',
        file_item_7:
          arrData.files.item_7 && arrData.files.item_7.url
            ? arrData.files.item_7.url
            : '',
        file_item_19:
          arrData.files.item_19 && arrData.files.item_19.url
            ? arrData.files.item_19.url
            : '',
        file_franchisees:
          arrData.files.franchisees && arrData.files.franchisees.url
            ? arrData.files.franchisees.url
            : '',
      }


      const likeResponse = await $axios.get(`/api/v1/likes/get/${franchise.franchise_id}`)
      likes = likeResponse.data.result
    } catch (e) {
      console.log(e)
    }
    const authenticated = store.getters['auth/check']

    if (authenticated) await store.dispatch('auth/fetchUserFranchise')

    if (franchise === null) {
      notFound = true
    } else if (
      strictIndustryMode &&
      route.params.industry !== franchise.slug_industry
    ) {
      notFound = true
    }

    if (notFound) {
      error({statusCode: 404, message: 'Page not found'})
    }
    return {
      arrData,
      likes,
      franchise,
      slug,
    }
  },
  data: () => ({
    eventOpenFullFDD: HUBSPOT_EVENT_TYPES.openFullFDD,
    eventOpenItem7: HUBSPOT_EVENT_TYPES.openItem7,
    eventOpenItem19: HUBSPOT_EVENT_TYPES.openItem19,
    eventOpenFranchiseesList: HUBSPOT_EVENT_TYPES.openFranchiseesList,
    modalRatingId: MODAL_RATING_FRANCHISE_ID,
    showCompanyPagesLanding: false,
    modals: {
      franchiseEdit: {
        text:
          'You are signed in as franchisee and you cannot edit this page. Please contact us at <a href="mailto:info@franchise123.com">info@franchise123.com</a> if something needs to be corrected or for any other concerns.',
      },
      franchisorEdit: {
        text:
          'You cannot edit this page. This franchise was claimed by another franchisor. Please contact us at <a href="mailto:info@franchise123.com">info@franchise123.com</a> if something needs to be corrected or for any other concerns.',
      },
      franchisorBeginToday: {
        text:
          ' You are signed in as a franchisor. You need to sign in as franchisee to buy a franchise. If you wish to buy this franchise please <a href="#" class="logout">sign out</a> and try again.',
      },
      ownerBeginToday: {
        text: 'You are signed in as the owner of this brand. You cannot buy this franchise.',
      },
      franchiseClaim: {
        text:
          'You are signed in as a franchisee. You need to sign in as franchisor to claim a business. If you are the owner of this business please <a href="#" class="logout">sign out</a> and try again.',
      },
      franchisorClaim: {
        text:
          'This franchise was claimed by another franchisor. If you are the owner of this business you can claim it under a separate account. Please <a href="#" class="logout">sign out</a> from your current account and try again.',
      },
      franchisorLike: {
        text: 'You are signed in as a Franchisor. If you are interested in buying this franchise please register a new account as a Franchisee. Please use another email to sign up as a Franchisee.',
      },
      franchisorCompare: {
        text: 'You are signed in as a Franchisor. If you are interested in buying this franchise please register a new account as a Franchisee. Please use another email to sign up as a Franchisee.',
      },
      franchisorFdd: {
        text: 'You are signed in as a franchisor. You need to sign in as a franchisee to buy a franchise. If you wish to buy this franchise please sign out and try again.',
      },
    },
    currentModal: {},
    screenWidth: 0,
    currentUrl: '',
    collapseIsOpen: false,
    userFranchise: null,
    franchise: {},
    previousParams: null,
    prevRoute: null,
    busyBeginToday: false,
  }),
  computed: {
    ...mapGetters({
      franchisesArr: 'business/franchiseList',
      user: 'auth/user',
      check: 'auth/check',
      user_franchise: 'auth/user_franchise',
      authenticated: 'auth/check',
      show_preloader: 'account/show_preloader',
      inProgressBusinessClientStep: 'franchisee-steps/inProgressBusinessClientStep',
      company: 'company/company',
      businessFilterParams: 'business/filter',
      franchiseListCompetitors: 'business/franchiseListCompetitors',
      restoreFilter: 'directories/restoreIndustryFilter',
      pendingListArr: 'account/pendingListArr',
    }),
    titleCollapseButton() {
      return this.collapseIsOpen ? 'less about company' : 'more about company'
    },
    showClaimButton() {
      return !(
        this.user_franchise !== null &&
        this.user_franchise.hasOwnProperty('franchise_id') &&
        this.user_franchise.franchise_id === this.franchise.id
      )
    },
    currentRating() {
      return this.franchise.surveyRatings.length > 0 ? this.franchise.surveyRatings : this.franchise.selfRatings
    },
    inPendingList(){
      const findIndex = this.pendingListArr.findIndex(item => Number(item) === Number(this.franchise.id))
      return findIndex >= 0
    },
  },
  watch: {
    async company(newVal) {
      await this.updateFilterCompetitors({industry: newVal.industry})
      await this.fetchBusinessCompetitors()
    },
  },
  async mounted() {
    this.setRestoreIndustryFilter(true)
    await this.$store.dispatch('account/setShowPreloader', true)
    await this.$store.dispatch('company/fetchCompany', {params: this.$route.params.company})

    if (this.slug) {
      this.openCompanyPagesLanding()
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.changeScreenWidth)
      this.changeScreenWidth()
    })

    this.previousFilter = {...this.businessFilterParams}
    await this.getFranchises()
    await this.$store.dispatch('business/fetchClick', false)
  },
  async created() {
    if (process.client) {
      await this.checkToken()
      this.currentUrl = document.location.href
    }
  },
  beforeDestroy() {
    if (this.restoreFilter) {
      this.$store.commit('business/SET_FILTER_ITEM', {
        key: 'industry',
        value: this.previousFilter.industry,
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    ...mapActions({
      fetchComparisonsList: 'account/fetchComparisonsList',
      fetchBusinessCompetitors: 'business/fetchBusinessCompetitors',
      updateFilterCompetitors: 'business/updateFilterCompetitors',
      fetchLikeList: 'account/fetchLikeList',
      fetchProgressList: 'account/fetchProgressList',
      setUserClick: 'account/setUserClick',
      updateSurveyRatingUserData: 'rating/updateSurveyRatingUserData',
      updateIndustryFromCompanyPage: 'business/updateIndustryFromCompanyPage',
      setRestoreIndustryFilter: 'directories/setRestoreIndustryFilter',
      updatePendingList: 'account/updatePendingList',
    }),
    searchByIndustry(title) {
      this.setRestoreIndustryFilter(false)
      this.updateIndustryFromCompanyPage({
        key: 'industry',
        value: title,
      })
      this.$router.push('/franchise-directory')
    },
    searchByTag(id) {
      this.$store.commit('business/SET_FILTER_ITEM', {
        key: 'allowed_tags_id',
        value: id,
      })
      this.$router.push('/franchise-directory')
    },
    openCompanyPagesLanding() {
      if (LANDING_OLD_LIST.includes(this.franchise.id) || this.franchise.websiteUrl.includes(LANDING_WEBSITE_PREFIX)) {
        window.open(this.franchise.websiteUrl, '_blank')
      } else {
        this.showCompanyPagesLanding = true
      }
    },
    closeCompanyPagesLanding() {
      this.showCompanyPagesLanding = false
    },
    adaptValue(val) {
      return val || 'N/A'
    },
    changeScreenWidth() {
      this.screenWidth = document.documentElement.clientWidth
    },
    getFilteredFranchises() {
      if (!this.franchiseListCompetitors.length)
        return []

      return this.franchiseListCompetitors.filter((item) => item.id !== this.franchise.id).slice(0, 3)
    },
    handlerOpenFile(franchise, link, eventType = '') {
      this.handlerBeginTodayButton(franchise, false)
      this.showFile(link, eventType)
    },
    showFile(link, eventType = '') {
      if (link === '')
        return

      if (!this.authenticated) {
        this.showFranchiseeFastRegistration(this.franchise)
        return
      }

      if (this.user.isFranchisor) {
        this.fireModalMessage({message: this.modals.franchisorFdd.text})
        return
      }

      if (this.user.isFranchisee) {
        this.hsCustomEvent(this.franchise.id, eventType)
        this.$store.dispatch('iframe/openModal', link)
      }
    },
    async toggleCompare() {
      if (this.authenticated) {
        try {
          if (this.user.isFranchisee) {
            await this.handleClickButton()
            this.setUserClick(true)
            await this.$store.dispatch('business/toggleComparison', this.franchise)
            await this.fetchComparisonsList()
          }
          if (this.user.isFranchisor)
            await this.fireModalMessage({message: this.modals.franchisorCompare.text})
        } catch (e) {
        }
      } else {
        await this.showFranchiseeFastRegistration(this.item)
      }
    },
    openFranchiseSite(url) {
      window.open(url, '_blank')
    },
    async getFranchises() {
      this.$store.commit('business/SET_FILTER_ITEM', {
        key: 'industry',
        value: this.franchise.industry,
      })
      this.$store.commit('business/SET_SORTING', {sortBy: 'number_of_locations', sortDesc: true})
      await this.$store.dispatch('business/fetchBusiness')
      await this.$store.dispatch('account/setShowPreloader', false)
    },
    /**
     *
     * @param item
     * @returns {string}
     */
    checkStatus(item) {
      let text = 'Begin Today'
      if (this.authenticated) {
        if (this.user.isFranchisee) {
          if (item.flow_status === 'flow_in_progress') {
            text = 'In Progress'
          } else if (item.flow_status === 'flow_closed') {
            text = 'Closed'
          }
        }
      }
      return text
    },
    /**
     *
     * @param company
     * @param isRedirect
     * @returns {Promise<void>}
     */
    async handlerBeginTodayButton(company, isRedirect = true) {

      if (!this.authenticated && !this.inPendingList) {
        const listArr = cloneDeep(this.pendingListArr)
        listArr.push(this.franchise.id)
        this.updatePendingList(listArr)
      }

      if (!this.authenticated) {
        await this.showFranchiseeFastRegistration(this.franchise)
        return
      }

      if (this.user.isFranchisor) {
        await this.fireModalMessage({message: this.modals.franchisorBeginToday.text})
        return
      }

      this.busyBeginToday = true
      await this.hsCustomEvent(this.franchise.id, 'begin-today')
      if (this.user.isFranchisee) {
        if (this.franchise.slug) {
          if (company.flow_status === 'flow_not_started') {
            this.setUserClick(true)
            await this.handleClickButton()
            await this.$axios.get(`/api/v1/business-client-steps/${company.slug}/franchisee-steps`)
            await this.$store.dispatch('company/fetchCompany', {
              params: this.$route.params.company,
            })
            await this.$store.dispatch('business/fetchProgress')
            this.checkStatus(company)
            await this.fetchProgressList()
          } else if (isRedirect) {
            await this.$router.push(`/account/dashboard`)
          }
        }
        this.busyBeginToday = false
        return
      }

      if (this.user_franchise.franchise_id === this.franchise.id) {
        await this.fireModalMessage({message: this.modals.ownerBeginToday.text})
      }
      this.busyBeginToday = false
    },
    async handleClickButton() {
      await this.$store.dispatch('business/fetchClick', true)
    },
    async handlerClickEditButton() {
      if (this.authenticated) {
        if (this.user.user_type === USER_TYPE_FRANCHISEE) {
          this.fireModalMessage({message: this.modals.franchiseEdit.text})
          return
        }

        if (this.user.user_type !== USER_TYPE_FRANCHISOR) return

        if (this.user_franchise.franchise_id !== this.franchise.id) {
          this.fireModalMessage({message: this.modals.franchisorEdit.text})
        } else {
          await this.$router.push('/account-franchisor')
        }
        return
      }
      await this.$store.dispatch('login/openModal')
    },
    handlerClickBackButton() {
      if (this.prevRoute?.path === '/')
        this.$router.push('/franchise-directory')

      if (this.prevRoute?.path !== '/')
        this.$router.go(-1)
    },
    async handlerClaimCompanyButton() {
      if (!this.authenticated) {
        await this.$router.push({ path: this.$route.path, query: { step_register_franchisor: '1' }})
        await this.$store.dispatch('registration/setUserType', USER_TYPE_FRANCHISOR)
        await this.$store.dispatch('registration/setFranchiseId', this.franchise.id)
        this.$bvModal.show(MODAL_SIGN_UP_FRANCHISOR_ID)
        return
      }

      if (this.user.isFranchisee) {
        await this.fireModalMessage({message: this.modals.franchiseClaim.text})
      }

      if (this.user.isFranchisor) {
        await this.fireModalMessage({message: this.modals.franchisorClaim.text})
      }
    },
    async showStartCooperationModal() {
      if (!this.authenticated) {
        await this.$store.dispatch('registration/openModal')
      } else if (this.user.user_type === USER_TYPE_FRANCHISEE) {
        if (!this.franchise.like) {
          await this.like()
        }
        if (!this.franchise.in_comparison) {
          await this.$store.dispatch('business/toggleComparison', this.franchise)
        }
      }
    },
    async toggleLike() {
      if (this.authenticated) {
        try {
          if (this.user.isFranchisee) {
            this.setUserClick(true)
            await this.handleClickButton()
            await this.$store.dispatch('business/toggleLike', this.franchise)
            await this.fetchLikeList()
          }
          if (this.user.isFranchisor)
            await this.fireModalMessage({message: this.modals.franchisorLike.text})
        } catch (e) {
          console.log(e)
        }
      } else {
        await this.showFranchiseeFastRegistration(this.franchise)
      }
    },
    async checkToken() {
      if (this.check && this.user.isFranchisor)
        return

      const token = this.$route.query?.token ?? null
      const channel = this.$route.query?.channel ?? null

      if (token === null)
        return

      const url = `api/v2/business-survey-rating/${token}/questions`

      try {
        const {data} = await this.$axios.get(url)
        this.updateSurveyRatingUserData({
          token,
          channel,
          questions: data.result,
          franchiseId: this.franchise.id,
          franchiseName: this.franchise.business_name ?? '',
        })
        this.$bvModal.show(this.modalRatingId)
      } catch (e) {

      }
    },
  },
  head() {
    const title = this.franchise.seo_meta_title
    const keywords = this.franchise.seo_meta_keywords
    const description = this.franchise.seo_meta_description ?? this.franchise.short_descr

    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          hid: 'keywords',
          property: 'keywords',
          content: keywords,
        },
        {
          hid: 'description',
          name: 'description',
          property: 'description',
          content: description,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${title} | Franchise123`,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: process.env.baseUrl,
        },
      ],
    }
  },
}
