//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapGetters,
  mapActions,
} from "vuex"
import BlogHome from "~/components/BlogHome"
import ContactFormRadio from "~/components/form/ContactFormRadio"

export default {
  name: 'RadioPage',
  components: {
    ContactFormRadio,
    BlogHome,
  },
  computed: {
    ...mapGetters({
      articlesRecentArr: "blog/articlesRecentArr",
    }),
  },
  mounted() {
    this.fetchRecentArticles(5)
  },
  beforeDestroy() {
    this.$store.dispatch('nav/setNavOverlap', false)
  },
  methods: {
    ...mapActions({
      fetchRecentArticles: 'blog/fetchRecentArticles'
    }),
  },
  head() {
    const title = 'Own a fitness franchise'
    const descr = 'Own a fitness franchise and open the doors tomorrow for a total investment of $60,000'
    const keywords = 'franchises for sale, Franchise Opportunities'
    return {
      title,
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ]
    }
  }
};
