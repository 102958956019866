//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from "vuex"

export default {
  mounted() {
    if (process.client) {
      if (document) {
        document.getElementById("body").className = "page-about";
        document.querySelector("nav").className = "nav";
        document.getElementById("main-layout-bg").style.display = "none";
      }
    }
    if(typeof this.$route.query?.open_contact_us !== "undefined")
      this.openModal()
  },
  methods:{
    ...mapActions({
      openModal:'modals/contact/show'
    })
  },
  destroyed() {
    if (process.client) {
      if (document) {
        document.getElementById("body").className = "";
        document.querySelector("nav").className = "nav";
      }
    }
  },
  head() {
    const title = 'About Franchise123';
    const descr = 'Franchise123 exhibits you with tons of franchising opportunities. Learn more about Franchise123 before you opt for any of the franchises we offer.';
    const keywords = '';
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ]
    }
  }
};
