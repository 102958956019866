//
//
//
//
//
//
//
//
//

import 'vue-loading-overlay/dist/vue-loading.css'
import CompanyPageLandingNew from "@/components/CompanyPageLandingNew";
import {mapGetters} from "vuex";

export default {
  layout: "landing",
  middleware:[
    'accountFranchisor'
  ],
  components: {
    CompanyPageLandingNew,
  },
  async asyncData({route, store}) {
    await store.dispatch('landing/fetchFranchise', {
      franchise_id: route.query.franchise_id,
      version_id: route.query.version_id,
    });
  },
  computed: mapGetters({
    landing: 'landing/landing'
  }),
};
