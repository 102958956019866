//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlogPost from "@/components/blog/BlogPost";
import {mapGetters} from "vuex"
import BlogLayout from "@/components/layout/BlogLayout"
import BlogPostsSidebar from "@/components/blog/BlogPostsSidebar"
import BlogBackButton from "@/components/blog/BlogBackButton"

export default {
  name: 'PageBlogPost',
  components: {
    BlogPost,
    BlogPostsSidebar,
    BlogLayout,
    BlogBackButton,
  },
  async asyncData({route, store}) {
    const post = route.params.post ?? null

    await store.commit("blog/SET_STATUS_FETCH_DATA", true)
    if (process.client) {
      window.scrollTo(0, 0)
    }

    if (post)
      await store.dispatch('blog/fetchArticle', post)

    await store.dispatch('blog/fetchRecentArticles')
    await store.dispatch('blog/fetchCategoryList')
  },
  data() {
    return {
      isMainPage: null,
    }
  },
  computed: {
    ...mapGetters({
      articleObj: "blog/articleObj",
      articlesRecentArr: "blog/articlesRecentArr",
      categoryListArr: "blog/categoryListArr",
      seoAttributes: "blog/seoAttributes",
      busy: "blog/busy",
    }),
    currentPage() {
      return this.$route.query.page ?? '1'
    },
    backUrl() {
      if (!process.client) {
        return ``
      }

      if (this.$nuxt.context.from) {
        return this.$nuxt.context.from
      }

      if (this.articleObj.categories[0]) {
        return this.articleObj.categories[0].url
      }

      return ``
    },
  },
  async mounted() {
    await this.$store.commit("blog/SET_STATUS_FETCH_DATA", false)
  },
  methods: {
    async handleChangePage(pageNum) {
      const category = this.$route.params.category ?? null
      const search = this.$route.params.search ?? null
      let url = `/blog/?page=${pageNum}`
      if (category)
        url = `/blog/category/${category}?page=${pageNum}`
      if (search) {
        url = `/blog/search/${search}?page=${pageNum}`
      }
      await this.$router.push(url)
      await this.updateArticleList(pageNum)
    },
    async updateArticleList(page) {
      await this.$store.commit("blog/BLOG_SET_PAGINATION", {
        page
      })
      await this.$store.dispatch('blog/fetchArticlesList')
    }
  },
  head() {
    const title = this.seoAttributes.title ? `${this.seoAttributes.title}` : `${this.articleObj.title}`
    const descr = this.seoAttributes.description ?? this.articleObj.content_short_clear
    const keywords = this.seoAttributes.keywords ?? "blog, business, franchisee, franchisor, new"
    const logo = this.articleObj.logo ?? ''
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          hid: "description",
          name: 'description',
          property: "description",
          content: descr,
        },
        {
          hid: "keywords",
          property: "keywords",
          content: keywords,
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: process.env.baseUrl
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: descr,
        },
        {
          property: "og:image",
          content: logo,
        },
      ]
    }
  },
}
