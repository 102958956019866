//
//
//
//
//

import DashboardFranchiseeFavorite from "@/components/dashboards/viewFranchisee/DashboardFranchiseeFavorite";
import {mapActions} from 'vuex';

export default {
  name:'AccountFavoritePage',
  components: {DashboardFranchiseeFavorite},
  async created() {
    const currentPage = this.$route.query.page ?? 1
    await this.setShowPreloader(true)
    await this.updateLikeCurrentPage(currentPage)
    await this.fetchLikeList()
  },
  methods:{
    ...mapActions({
      fetchLikeList: 'account/fetchLikeList',
      setShowPreloader: 'account/setShowPreloader',
      updateLikeCurrentPage: 'account/updateLikeCurrentPage',
    })
  }
}
