//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex"

import BlogLayout from "@/components/layout/BlogLayout"
import Loader from "@/components/Loader"
import PaginationDefault from "@/components/paginations/PaginationDefault"
import BlogCategoryList from "@/components/blog/BlogCategoryList"
import BlogPostsList from "@/components/blog/BlogPostsList"
import BlogPostsSidebar from "@/components/blog/BlogPostsSidebar"
import BlogBackButton from "@/components/blog/BlogBackButton"

export default {
  name:'PageBlogIndex',
  components: {
    BlogBackButton,
    BlogCategoryList,
    PaginationDefault,
    Loader,
    BlogLayout,
    BlogPostsList,
    BlogPostsSidebar,
  },
  async asyncData({route, store}) {
    const search = route.query.search ?? null
    const category = route.params.category ?? null
    const isMainPage = !search && !category
    const limitList = {
      main: 3,
      general: 5,
    }

    await store.commit("blog/SET_STATUS_FETCH_DATA", true)
    if (process.client) {
      window.scrollTo(0, 0)
    }

    if (search !== null) {
      await store.commit("blog/BLOG_SET_SEARCH", search)
    }

    await store.commit("blog/BLOG_SET_PAGINATION", {
      page: route.query.page ?? '1',
      per_page: isMainPage ? limitList.main : limitList.general
    })
    await store.commit("blog/BLOG_SET_CATEGORY", category)

    await store.dispatch('blog/fetchArticlesList')
    await store.dispatch('blog/getSeoAttributes', {type: 'category', slug: category})
    await store.dispatch('blog/fetchRecentArticles')
    await store.dispatch('blog/fetchCategoryList')
  },
  data() {
    return {
      pagePostListPosY: null,
    }
  },
  computed: {
    ...mapGetters({
      articlesArr: "blog/articlesArr",
      paginationObj: "blog/paginationObj",
      articlesRecentArr: "blog/articlesRecentArr",
      categoryListArr: "blog/categoryListArr",
      categoryObj: "blog/categoryObj",
      seoAttributes: "blog/seoAttributes",
      busy: "blog/busy",
    }),
    currentPage() {
      return this.$route.query.page ?? '1'
    },
    categoryPage() {
      return this.$route.params.category ?? null
    },
    searchPage() {
      return this.$route.query.search ?? null
    },
    isMainPage() {
      return !this.categoryPage && !this.searchPage
    }
  },
  async mounted() {
    await this.setLoaderStatus(false)
  },
  methods: {
    async handleChangePage(pageNum) {
      const url = this.detectPageUrl(pageNum)

      await this.setLoaderStatus(true)
      await this.$router.push(`${url}`)
      await this.updateArticleList(pageNum)
      this.scrollToTopList()

      await this.setLoaderStatus(false)
      this.scrollToTopList()
    },
    async updateArticleList(page) {
      await this.$store.commit("blog/BLOG_SET_PAGINATION", {
        page
      })
      await this.$store.dispatch('blog/fetchArticlesList')
    },
    detectPageUrl(pageNum) {
      let url = `/blog/?page=${pageNum}`

      if (this.categoryPage)
        url = `/blog/category/${this.categoryPage}?page=${pageNum}`
      if (this.searchPage) {
        url = `/blog?search=${this.searchPage}&page=${pageNum}`
      }

      return url
    },
    scrollToTopList() {
      const blockContainer = document.getElementById("blog-container")
      const headerWrap = document.getElementById("nav-wrap")
      const defaultOffset = 32

      let posY = blockContainer.offsetTop - headerWrap.offsetHeight - defaultOffset
      if (headerWrap.offsetHeight === 0) {
        posY -= document.getElementById("nav__container").offsetHeight
      }

      if (posY > 0) {
        this.pagePostListPosY = posY
      }

      window.scrollTo(0, this.pagePostListPosY)
    },
    async setLoaderStatus(status) {
      await this.$store.commit("blog/SET_STATUS_FETCH_DATA", status)
    },
  },
  head() {
    const title = this.seoAttributes.title ? `${this.seoAttributes.title}` : `${this.categoryObj.title}`
    const descr = this.seoAttributes.description ??
      "Franchise123 exhibits you with tons of franchising opportunities. Learn more about Franchise123 before you opt for any of the franchises we offer."
    const keywords = this.seoAttributes.keywords ??
      "blog, business, franchisee, franchisor, new"

    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          hid: "description",
          property: "description",
          name: 'description',
          content: descr
        },
        {
          hid: "keywords",
          property: "keywords",
          content: keywords
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website"
        },
        {
          hid: "og:url",
          property: "og:url",
          content: process.env.baseUrl
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr
        }
      ]
    };
  },
}
