import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9f28b130&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=9f28b130&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f28b130",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogCategoryList: require('/root/repo/components/blog/BlogCategoryList.vue').default,BlogBackButton: require('/root/repo/components/blog/BlogBackButton.vue').default,BlogPostsList: require('/root/repo/components/blog/BlogPostsList.vue').default,Loader: require('/root/repo/components/Loader.vue').default,BlogPostsSidebar: require('/root/repo/components/blog/BlogPostsSidebar.vue').default})
