//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from "@/components/Loader";
import DownloadContactForm from "@/components/modals/DownloadContactForm";
import {mapActions, mapGetters} from "vuex";
import DownloadIcon from 'assets/icons/DownloadIcon.svg?inline'

export default {
  components: {
    DownloadContactForm,
    Loader,
    DownloadIcon,
  },
  data: () => ({
    showDownloadModal: false,
    formFileUrl: ''
  }),
  computed: {
    ...mapGetters({
      pagesIndustryReportArr: 'pages/pagesIndustryReportArr',
      busy: 'pages/busy'
    })
  },
  mounted() {
    this.fetchPagesIndustryReport()
  },
  methods: {
    ...mapActions({
      fetchPagesIndustryReport: 'pages/fetchPagesIndustryReport'
    }),
    handlerClickDownload(item) {
      this.formFileUrl = item.report.url
      this.showDownloadModal = true
    },
    closeDownloadModal() {
      this.showDownloadModal = false
      this.formFileUrl = ''
    },
  },
  head() {
    const title = 'Franchising Industry Reports';
    const descr = 'Read the latest franchising industry reports with exclusive research, analysis and information on all types of opportunities spanning across 38 industries.';
    const keywords = '';
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ]
    }
  },
};
