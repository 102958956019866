//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BookFddIcon from "@/components/icons/BookFddIcon";
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/Loader";
import ArrowCodeIcon from "@/components/icons/ArrowCodeIcon";
import ModalQuestions from "@/components/modals/ModalAccount/ModalQuestions";

export default {
  name: 'AccountPage',
  middleware:[
    'accountFranchisee'
  ],
  components: {BookFddIcon, ModalQuestions, ArrowCodeIcon, Loader},
  data() {
    return {
      tabs:[
        {
          key: "my-matches",
          title: "My Matches"
        },
        {
          key: "dashboard",
          title: "Dashboard"
        },
        {
          key: "profile",
          title: "Profile"
        },
        {
          key: "franchise-university",
          title: "Franchise University"
        },
        {
          key: "favorite-franchises",
          title: "Favorite Franchises"
        },
        {
          key: "comparisons",
          title: "Comparisons"
        }
      ],
      currentTab: 'my-matches',
      routeNamePrefix: "account",
      checkData: true,
      answered: 0,
      activeItem: '',
      isOpenMobileMenu: false,
      showMessageQuestionnaire: false
    };
  },
  computed: {
    ...mapGetters({
      answers: "account/answers/answers",
      questionnaireFinished: "account/question/finished",
      show_preloader: "account/show_preloader",
    }),
    isLoadContent(args = false) {
      return args;
    },
  },
  watch: {
    $route(to) {
      this.currentTab = this.getTabName(to.path);
    },
    questionnaireFinished() {
      this.fetchAnswers();
    },
    answers() {
      this.showMessageQuestionnaire = this.answers.length === 0
    },
  },
  async created() {
    this.currentTab = this.getTabName(this.$route.path);
  },
  async mounted() {

    if (this.answers.length) {
      await this.$store.dispatch("account/question/setFinished", true);
    }
  },
  methods: {
    ...mapActions({
      fetchAnswers: "account/answers/fetchAnswers",
      fetchQuestions: "account/answers/fetchQuestions"
    }),
    removeDash(item) {
      return item.replace('-', ' ');
    },
    openEBook() {
      if (process.client) {
        window.open('/documents/F123 - What is the FDD-250121.pdf', '_blank')
      }
    },
    updateAnswers(arg) {
      if (arg) {
        this.fetchAnswers();
      }
    },

    getActiveItem(params) {
      this.activeItem = params
    },

    changeTab(to, menuCollapseId = '') {
      this.isOpenMobileMenu = !this.isOpenMobileMenu;
      if (menuCollapseId !== '')
        this.$root.$emit('bv::toggle::collapse', menuCollapseId)

      if (this.currentTab === null || this.currentTab === to)
        return;
      this.currentTab = this.getTabName(to);
      this.$store.dispatch('account/setShowPreloader', true);
      this.$router.push(`/account/${to}`);
    },
    handleTabChange(tab) {
      this.$router.push({path: `/${this.routeNamePrefix}/${tab}`});
    },
    getTabName(path) {
      const trimPath = path.replace(/\//g, '')
      return trimPath.replace(this.routeNamePrefix, "")
    }
  }
};
