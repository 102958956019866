//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapGetters,
  mapState,
} from "vuex"
import IndustryIcon from "@/components/icons/IndustryIcon"

export default {
  name:'ListFranchisesPageIndex',
  components: {
    IndustryIcon,
  },
  data () {
    return {
      industryList: null,
    }
  },
  computed: {
    ...mapState({
      industries: state => state.directories.industries,
    })
  },
  async mounted() {
    await this.$store.dispatch('directories/fetchIndustries');
    this.industryList = this.industries;
  },
  head() {
    const title = 'Find Franchise Opportunities by Industry';
    const descr = '';
    const keywords = '';
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ]
    }
  },
}
