//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"
import PromoHome from "../components/PromoHome"
import {
  MODAL_SIGN_UP_FRANCHISE_ID,
} from "~/store/constants"

export default {
  components: {
    PromoHome,
  },
  head() {
    return {
      title: "Franchises for Sale, Opportunities & Insights",
      meta: [
        {
          name: 'description',
          hid: "description",
          property: "description",
          content:
            "Franchise123 is your ultimate hub for A to Z franchising from franchise opportunities, information, and exclusive insights to available franchises for sale."
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website"
        },
        {
          hid: "og:title",
          property: "og:title",
          content: "Franchises for Sale, Opportunities & Insights | Franchise123"
        },
        {
          hid: "og:url",
          property: "og:url",
          content: process.env.baseUrl
        },
        {
          hid: "og:description",
          property: "og:description",
          content:
            "Franchise123 is your ultimate hub for A to Z franchising from franchise opportunities, information, and exclusive insights to available franchises for sale."
        },
        {
          hid: "keywords",
          property: "keywords",
          content: "franchises for sale, Franchise Opportunities"
        }
      ]
    };
  },
  computed: mapGetters({
    authenticated: "auth/check",
  }),
  beforeMount() {
    if (this.authenticated) {
      return this.$router.push('/')
    }

    let params = this.$route.query
    let utmCampaignList = [
      'Franchise123',
    ];
    if( utmCampaignList.includes(params.utm_campaign) ){
      setTimeout(async () => {
        await this.showFranchiseeFastRegistration()
      }, 1000);
    }
 },
  methods: {
    async showFranchiseeFastRegistration(item = null, showFdd= false) {
      this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID)
      await this.$store.commit('account/SET_SELECTED_FRANCHISE', item)
      await this.$store.commit('account/SET_SHOW_FDD', showFdd)
    },
  }
};
