//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from 'vuex'
import StringMask from "string-mask"
import {MASK_PHONE} from "@/store/constants"
import PaginationDefault from "~/components/paginations/PaginationDefault"

import storeMixin from "~/components/mixins/storeMixin"

export default {
  components: {
    PaginationDefault,
  },
  mixins: [
    storeMixin,
  ],
  data() {
    return {
      fields: [
        {
          key: 'full_name',
          label: 'Name',
          stickyColumn: true,
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'phone',
          label: 'Phone number',
        },
        {
          key: 'created_at_formatted',
          label: 'Date',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      versionApproved: 'account-franchisor/versionApproved',
      paymentPermissions: 'payment/permissions',
      franchise: 'business-v2/franchise',
      paginationObj: 'account-franchisor/forms/listPagination',
      forms: 'account-franchisor/forms/list',
      busy: 'account-franchisor/forms/busy',
    })
  },
  async mounted() {
    await this.checkPermissions()
    await this.getData()
  },
  methods: {
    ...mapActions({
      fetchBusinessSteps: 'business-steps/fetchBusinessSteps',
      updateFranchiseeViewedId: 'account-franchisor/updateFranchiseeViewedId',
      fetchFormsList: 'account-franchisor/forms/fetchFormsList',
    }),
    async checkPermissions() {
      const permissionType = this.paymentPermissions.CRM
      let allowAction = true
      let message = ''

      if (!this.versionApproved) {
        allowAction = false
        message = this.$t('accountFranchisor.accessMessages.notApprovedFranchisor')
      }

      if (!permissionType.access) {
        allowAction = false
        message = permissionType.message
      }

      if (!allowAction) {
        await this.fireModalMessage({ message })
        await this.$router.push('/account-franchisor/dashboard/')
      }
    },
    getValue(value, defaultValue='-') {
      return !value || value === ''
        ? defaultValue
        : (new StringMask(MASK_PHONE)).apply(value.replace(/[^0-9]/g, ""))
    },
    async getData(page = 1) {
      this.fetchFormsList({
        page,
      })
    },
    async handleChangePage(pageNum) {
      await this.getData(pageNum)
    },
  }
}
