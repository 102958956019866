//
//
//

import 'vue-loading-overlay/dist/vue-loading.css'
import CompanyPageLanding from "@/components/CompanyPageLanding";

export default {
  components: {CompanyPageLanding},
  layout: "landing",
};
