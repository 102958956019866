var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['steps-page-wrapper']},[_c('div',{staticClass:"steps-page-container"},[_c('div',{staticClass:"mobile-head"},[_c('div',{staticClass:"mobile-head-nav"},[_c('router-link',{staticClass:"back-btn",attrs:{"to":_vm.buildAccountFranchisorUri('/account-franchisor/dashboard')}},[_c('arrow-left')],1),_vm._v(" "),_c('span',{staticClass:"mobile-head__title"},[_vm._v("Chat")])],1),_vm._v(" "),_c('div',{staticClass:"mobile-tabs"},[_vm._l((_vm.mobileTabs),function(tab,index){return [_c('div',{key:index,class:['mobile-tab', { active: _vm.activeMobileTabIndex === index }],on:{"click":function($event){_vm.activeMobileTabIndex = index}}},[_vm._v("\n            "+_vm._s(tab.title)+"\n          ")])]})],2)]),_vm._v(" "),_c('div',{class:[
        'steps-page',
        ("steps-page--tab-" + _vm.activeTabName) ]},[_c('div',{class:[
          'steps-page__left',
          'steps-page-left',
          { 'mobile-hide': !_vm.isActiveMessages } ]},[_c('div',{staticClass:"chat-list-header"},[_c('arrow-back',{staticClass:"steps-page-left__arrow-back",attrs:{"text-color":"#00A769","link-to":_vm.buildAccountFranchisorUri('/account-franchisor/dashboard'),"arrow-fill":"#00A769","is-button":false},on:{"click":_vm.closeChatWindow}},[_vm._v("\n            BACK\n          ")]),_vm._v(" "),_c('find-chat',{model:{value:(_vm.findChatName),callback:function ($$v) {_vm.findChatName=$$v},expression:"findChatName"}})],1),_vm._v(" "),_c('loader',{attrs:{"active":_vm.contactListLoading,"animation-size":"100px","height":"400px"}},[_c('contact-list',{staticClass:"steps-page-left__contact-list",attrs:{"contact-list":_vm.contactList,"minimize":false},on:{"contact-chose":_vm.handleContactChose}})],1),_vm._v(" "),_c('div',{staticClass:"pagination-wrapper"},[_c('pagination-default',{directives:[{name:"show",rawName:"v-show",value:(!_vm.contactListLoading),expression:"!contactListLoading"}],attrs:{"current-page":_vm.contactListPagination.current_page.toString(),"per-page":Number(_vm.contactListPagination.per_page),"total":_vm.contactListPagination.total,"align":"center"},on:{"change":_vm.contactListPaginationChange}})],1)],1),_vm._v(" "),_c('transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.businessStep),expression:"businessStep"}],class:[
            'steps-page__right',
            'steps-page-right',
            { 'mobile-hide': !_vm.isActiveDocuments } ]},[(_vm.businessStep)?_c('h1',{staticClass:"steps-page-right__step-name"},[_vm._v("\n            "+_vm._s(_vm.businessStep.name)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"chat-documents-head"},[_c('h1',{staticClass:"chat-documents-head__title"},[_vm._v("\n              Documents list\n            ")]),_vm._v(" "),_c('link-button',{attrs:{"text-color":"#00A769","is-button":""},on:{"click":_vm.handleClickAddNew}},[_vm._v("\n              + ADD NEW\n            ")])],1),_vm._v(" "),_c('document-list',{ref:"chat-documents-list",staticClass:"chat-documents__list",attrs:{"document-list":_vm.documentList,"loading":_vm.documentUploadLoading}}),_vm._v(" "),(_vm.businessStep)?_c('modal-add-document',{attrs:{"business-step-id":_vm.businessStep.business_step_id},on:{"add":_vm.onDocumentAdd},model:{value:(_vm.showModalNewDocument),callback:function ($$v) {_vm.showModalNewDocument=$$v},expression:"showModalNewDocument"}}):_vm._e()],1)])],1)]),_vm._v(" "),_c('modal-view-franchisee')],1)}
var staticRenderFns = []

export { render, staticRenderFns }