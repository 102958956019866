//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
  mapState
} from "vuex"
import {cloneDeep} from "lodash/fp"
import "vue-loading-overlay/dist/vue-loading.css"
import BaseButton from "@/components/buttons/BaseButton"
import DirectoryCard from "@/components/cards/DirectoryCard"
import AutoComplete from "@/components/form/AutoComplete"
import VCheckBox from "@/components/form/VCheckBoxV2"
import VRangeInputV2 from "@/components/form/VRangeInputV2"
import VSelect from "@/components/form/VSelect"
import VSelectRangeInputV2 from "@/components/form/VSelectRangeInputV2"
import FranchiseDirectoryPagination from "@/components/paginations/FranchiseDirectoryPagination"
import ViewSwitcher from "@/components/ViewSwitcher"
import Loader from "@/components/Loader"
import VBTable from "@/components/tables/VBTable"
import PdfBook from "@/components/information/PdfBook"

export default {
  name: 'PageFranchiseDirectoryIndex',
  components: {
    DirectoryCard,
    ViewSwitcher,
    FranchiseDirectoryPagination,
    BaseButton,
    VCheckBox,
    VRangeInputV2,
    VSelectRangeInputV2,
    VSelect,
    AutoComplete,
    Loader,
    VBTable,
    PdfBook,
  },
  scrollToTop: true,
  filters: {
    formatThousands(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  props: {
    sidebar: {
      type: Boolean,
      default: () => true
    }
  },
  async asyncData({route, store, error}) {
    const industrySlug = route.params.industry

    let directoryTitle = 'Franchise Directory: List of Franchises for Sale'
    let industryTitle = ''
    let correctIndustry = false

    if (industrySlug) {
      await store.dispatch('directories/fetchIndustries');
      const industries = store.getters['directories/industries'];
      for (const [fieldKey, fieldValue] of Object.entries(industries)) {
        if (fieldValue.slug === industrySlug) {
          industryTitle = fieldValue.title
          directoryTitle = `${fieldValue.short_title} Franchise Opportunities`
          correctIndustry = true
        }
      }
      if (!correctIndustry) {
        error({statusCode: 404, message: 'Page not found'})
        return false
      }
    }
    return {
      directoryTitle,
      industryTitle,
    }
  },
  data: () => ({
    fixedLoading: 'fixed-loading',
    labelClass: 'filter-labels',
    filters: {},
    pagination: {},
    applyFilter: false,
    value: [1, 40],
    awaitingSearch: false,
    isLoading: true,
    filtersCounter: 0,
    resetPagePaused: false,
    inputs: {
      industry: [],
      locations: [],
      tags: [],
      investment: [
        {
          title: "up to $10K",
          value: [0, 1000]
        },
        {
          title: "up to $20K",
          value: [0, 20000]
        },
        {
          title: "up to $30K",
          value: [0, 30000]
        },
        {
          title: "up to $40K",
          value: [0, 40000]
        },
        {
          title: "up to $50K",
          value: [0, 50000]
        },
        {
          title: "up to $60K",
          value: [0, 60000]
        },
        {
          title: "up to $70K",
          value: [0, 70000]
        },
        {
          title: "up to $80K",
          value: [0, 80000]
        },
        {
          title: "up to $90K",
          value: [0, 90000]
        },
        {
          title: "up to $100K",
          value: [0, 100000]
        },
        {
          title: "up to $200K",
          value: [0, 200000]
        },
        {
          title: "up to $300K",
          value: [0, 300000]
        },
        {
          title: "up to $400K",
          value: [0, 400000]
        },
        {
          title: "up to $500K",
          value: [0, 500000]
        },
        {
          title: "up to $1mln",
          value: [0, 1000000]
        },
        {
          title: "over $1 mln",
          value: [0, 10000000000]
        }
      ],
      number_of_locations1: [
        {
          title: "1 - 5",
          value: "1,5"
        },
        {
          title: "6 - 25",
          value: "6,25"
        },
        {
          title: "25 - 100",
          value: "25,100"
        },
        {
          title: "over 100",
          value: "100,10000000"
        }
      ],
      established: [],
      franchising_since: [],
      membership_level: [
        {
          title: "Business",
          value: "business"
        },
        {
          title: " Gold ",
          value: "gold"
        },
        {
          title: "Platinum",
          value: "platinum"
        }
      ]
    },
    formChangeTimeout: null,
    showMoreFilters: true,
    showMoreFiltersMobile: false,
    isLoadingMoreFilters: false,
    filterSort: {
      sortBy: "-membership,business_name",
      sortDesc: false
    },
    noChangeFilter: false
  }),
  computed: {
    ...mapGetters({
      items: "business/franchiseList",
      authenticated: "auth/check",
      user: "auth/user"
    }),
    ...mapState({
      businessFilters: state => state.business.filter,
      businessPagination: state => state.business.pagination,
      itemsState: state => state.business.itemsState,
      likedFranchises: state => state.business.likedFranchises,
      statesDirectory: state => state.directories.states,
      industriesDirectory: state => state.directories.industries,
      tagsDirectory: state => state.directories.tags,
    }),
    isPdfBookMob() {
      return false
    },
    tableViewFields() {
      return [
        {key: "index", label: "#", stickyColumn: true},
        {
          key: "business_image",
          label: "",
          sortable: false,
          class: "b-image"
        },
        {
          key: "business_name",
          label: "Franchise Name",
          sortable: true,
          stickyColumn: true,
          sortByFormatted: true,
          class: "b-name"
        },
        {key: "investment", sortable: true},
        {key: "average_revenue", sortable: true},
        {key: "franchise_fee", sortable: true},
        // { key: 'rating', sortable: true },
        {
          key: this.authenticated && this.user.isMasterAdmin ? "admin_button" : "compare",
          label: this.authenticated && this.user.isMasterAdmin ? "Admin" : "Compare",
          sortable: false
        },
        "FDD"
      ]
    }
  },
  watch: {
    showMoreFiltersMobile(newVal) {
      if (newVal) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "scroll";
      }
    },
    items() {
      this.pagination = cloneDeep(this.businessPagination);
    },
    "filters.business_name": async function (newVal) {
      this.setFilterItem("business_name", newVal);
      await this.setFirstPage();
      await this.getData(true);
    },
    "filters.industry": async function (newVal) {
      this.setFilterItem("industry", newVal);
      await this.setFirstPage();
      await this.getData();
    },
    "filters.investment": {
      deep: true,
      async handler(newVal) {
        this.setFilterItem("investment", newVal);
        await this.setFirstPage();
        await this.getData(true);
      }
    },
    "filters.allowed_states_id": async function (newVal) {
      this.setFilterItem("allowed_states_id", newVal);
      await this.setFirstPage();
      await this.getData();
    },
    "filters.allowed_tags_id": async function (newVal) {
      await this.setFilterItem("allowed_tags_id", newVal);
      await this.setFirstPage();
      await this.getData();
    },
    "filters.number_of_locations": {
      deep: true,
      async handler(newVal) {
        this.setFilterItem("number_of_locations", newVal);
        await this.setFirstPage();
        await this.getData(true);
      }
    },
    "filters.franchise_fee": {
      deep: true,
      async handler(newVal) {
        this.setFilterItem("franchise_fee", newVal);
        await this.setFirstPage();
        await this.getData(true);
      }
    },
    "filters.franchising_since": async function (newVal) {
      this.setFilterItem("franchising_since", newVal);
      await this.setFirstPage();
      await this.getData();
    },
    "filters.established": async function (newVal) {
      this.setFilterItem("established", newVal);
      await this.setFirstPage();
      await this.getData();
    },
    "filters.average_revenue": {
      deep: true,
      async handler(newVal) {
        this.setFilterItem("average_revenue", newVal);
        await this.setFirstPage();
        await this.getData(true);
      }
    },
    "filters.membership": async function (newVal) {
      this.setFilterItem("membership", newVal);
      await this.setFirstPage();
      await this.getData();
    },
    "filters.file_available": async function (newVal) {
      this.setFilterItem("file_available", newVal);
      await this.setFirstPage();
      await this.getData();
    },
    "filters.file_item_7_available": async function (newVal) {
      this.setFilterItem("file_item_7_available", newVal);
      await this.setFirstPage();
      await this.getData();
    },
    "filters.file_item_19_available": async function (newVal) {
      this.setFilterItem("file_item_19_available", newVal);
      await this.setFirstPage();
      await this.getData();
    },
    "filters.video": async function (newVal) {
      this.setFilterItem("video", newVal);
      await this.setFirstPage();
      await this.getData();
    }
  },
  created() {
    this.setDropDownOptions();
    this.sortingChanged(this.filterSort);

    this.resetPagePaused = true;
    this.filters = cloneDeep(this.businessFilters);
    this.pagination = cloneDeep(this.businessPagination);
    this.resetPagePaused = false;
  },
  async mounted() {
    this.isLoading = true;
    this.resetPagePaused = true;
    await this.fetchIndustries();
    this.inputs.industry = this.industriesDirectory;
    await this.fetchTags();
    this.inputs.tags = this.tagsDirectory;
    this.setPreselectedIndustry();
    await this.fetchStates();
    this.statesDirectory.forEach(el => {
      this.inputs.locations.push({
        title: `${el.name}, ${el.code}`,
        value: el.id
      });
    });
    this.applyFilter = true;
    // this.setFirstPage();
    this.resetPagePaused = false;

    await this.getData();
  },
  methods: {
    ...mapActions({
      fetchStates: "directories/fetchStates",
      fetchIndustries: "directories/fetchIndustries",
      fetchTags: "directories/fetchTags",
    }),
    setPreselectedIndustry() {
      if (this.industryTitle) {
        this.$store.commit("business/SET_FILTER_ITEM", {
          key: "industry",
          value: this.industryTitle,
        });
        this.filters.industry = this.industryTitle;
      }
    },
    setFilterItem(key, value) {
      this.$store.commit("business/SET_FILTER_ITEM", {
        key,
        value
      });
    },
    async getData(withAwait = false) {
      if (this.applyFilter) {
        this.isLoading = true;
        this.isLoadingMoreFilters = true;
        this.resetButtonStatus();

        if (withAwait) {
          clearTimeout(this.formChangeTimeout);
          this.formChangeTimeout = setTimeout(async () => {
            await this.$store.dispatch("business/fetchBusiness");
            this.isLoading = false;
            this.isLoadingMoreFilters = false;
          }, 1000);
        } else {
          await this.$store.dispatch("business/fetchBusiness");
          this.isLoading = false;
          this.isLoadingMoreFilters = false;
        }
      }
    },
    async comparisonPlus(idx) {
      await this.$store.dispatch("business/comparisonPlus", idx);
    },
    async handleChangePage(pageNumber) {
      this.isLoading = true;
      this.goToSwitcherPosition();
      this.$store.commit("business/SET_MAIN_FILTER_PAGINATION", {
        current_page: pageNumber
      });
      await this.getData();
    },
    // generate range date for select
    generateRangeDate(minYear = 1920) {
      const currentYear = new Date().getFullYear();
      const ranges = currentYear - minYear;
      return Array(ranges)
        .fill()
        .map((_, i) => {
          return {
            title: String(i + minYear),
            value: String(i + minYear)
          };
        });
    },
    setDropDownOptions() {
      this.inputs.established = this.generateRangeDate();
      this.inputs.franchising_since = this.generateRangeDate(1925);
    },
    resetButtonStatus() {
      if (this.filters) {
        let localCounter = 0;
        for (const key in this.filters) {
          if (
            this.filters[key] != null &&
            this.filters[key] != "" &&
            typeof this.filters[key] !== "object"
          ) {
            localCounter++;
          } else if (
            this.filters[key] != null &&
            this.filters[key] != "" &&
            typeof this.filters[key] === "object"
          ) {
            if (
              this.filters[key].min != null ||
              this.filters[key].max != null
            ) {
              localCounter++;
            }
          }
        }
        this.filtersCounter = localCounter;
      }
    },
    clearFilter() {
      // blocking request if filter empty
      this.applyFilter = false;
      this.isLoading = true;
      this.isLoadingMoreFilters = true;

      // form filters reset
      this.filters.investment = {min: null, max: null}
      this.filters.franchise_fee = {min: null, max: null}
      this.filters.average_revenue = {min: null, max: null}
      this.filters.number_of_locations = {min: null, max: null}
      this.filters.allowed_states_id = []
      this.filters.business_name = ''
      this.filters.industry = ''
      this.filters.allowed_tags_id = ''
      this.filters.established = ''
      this.filters.membership = ''
      this.filters.franchising_since = ''
      this.filters.file_available = null
      this.filters.file_item_7_available = null
      this.filters.file_item_19_available = null
      this.filters.video = null

      // this trigger for wait all filter set empty
      setTimeout(async () => {
        this.applyFilter = true;
        await this.getData();
      }, 333);
    },
    sortingChanged(e) {
      this.$store.commit("business/SET_SORTING", e);
      this.getData();
    },
    goToSwitcherPosition() {
      window.scrollTo(0, 0)
    },
    showFilterResult() {
      this.showMoreFiltersMobile = false;
      this.goToSwitcherPosition();
    },
    async setFirstPage() {
      if (this.resetPagePaused) {
        return false;
      }
      await this.$store.commit("business/SET_MAIN_FILTER_PAGINATION", {
        current_page: 1
      });
    }
  },
  head() {
    const title = this.directoryTitle;
    const descr = 'Interested in becoming an entrepreneur? Find franchising opportunities in our list of available franchises and start your journey as an entrepreneur.';
    const keywords = '';
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ]
    }
  },
};
