//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableMatches from '@/components/tables/TableMatches';

export default {
  name: 'PageDashboardFranchisee',
  components: {
    TableMatches,
  },
  scrollToTop: true,
  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        {
          key: 'franchise_id',
          label: '#',
          sortable: false,
        },
        {
          key: 'logo_url',
          label: 'Logo',
          sortable: false,
          class: 'b-image',
        },
        {
          key: 'business_name',
          label: 'Franchise Name',
          sortable: false,
        },
        {
          key: 'purchase_step',
          label: '',
          sortable: false,
        },
      ],
      stickyColumns: ['franchise_id', 'business_name'],
      itemsTotal: 1,
      itemsPerPage: 6,
      itemsCurrentPage: 1,
      paginationCurrentPage: 1,
      errors: [],
      showItems: false,
      hideMessage: false,
    };
  },
  watch: {
    async items() {
      if (this.paginationCurrentPage !== 1 && !this.items.length) {
        this.paginationCurrentPage = this.paginationCurrentPage > 2 ? this.paginationCurrentPage - 1 : 1
        await this.getPreferencesFromDB(this.paginationCurrentPage)
      }
      this.showItems = this.items.length;
      this.hideMessage = !this.items.length;
    },
  },
  async mounted() {
    this.applyStickyColumns();
    await this.getPreferencesFromDB();
  },
  methods: {
    async getPreferencesFromDB(page) {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`/api/v1/business-client-steps/franchisee-all-steps?
        perPage=${this.itemsPerPage}
        &currentPage=${page}`);

        this.items = [...data.data];

        if (data.data.length) {
          const fakeStep = {
            // get first item steps
            business_client_steps: data.data[0].business_client_steps,
            business_name: '',
            franchise_id: null,
            logo_url: '',
            slug: '',
          };
          this.items.unshift(fakeStep);
        }
        this.itemsTotal = data.total;
        this.itemsCurrentPage = data.current_page;
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
      await this.$store.dispatch('account/setShowPreloader', false);
    },
    /**
     * Set sticky column if mobile or tablet viewport
     */
    applyStickyColumns() {
      const viewportWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      this.fields = this.fields.map((value) => {
        const stickyColumn = this.stickyColumns.indexOf(value.key) !== -1;
        return { ...value, stickyColumn: stickyColumn && viewportWidth <= 1024 };
      });
    },
  },
};
