import { render, staticRenderFns } from "./_post.vue?vue&type=template&id=7a35572c&scoped=true&"
import script from "./_post.vue?vue&type=script&lang=js&"
export * from "./_post.vue?vue&type=script&lang=js&"
import style0 from "./_post.vue?vue&type=style&index=0&id=7a35572c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a35572c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogBackButton: require('/root/repo/components/blog/BlogBackButton.vue').default,BlogPost: require('/root/repo/components/blog/BlogPost.vue').default,Loader: require('/root/repo/components/Loader.vue').default,BlogPostsSidebar: require('/root/repo/components/blog/BlogPostsSidebar.vue').default})
