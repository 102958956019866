var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('account-franchisor-layout',[_c('template',{slot:"center"},[_c('div',{staticClass:"dashboard-left"},[_c('account-franchisor-dashboard-card',{staticClass:"dashboard__statistic dashboard-statistic"},[_c('div',{staticClass:"dashboard-statistic__left dashboard-statistic-left"},[_c('div',{staticClass:"dashboard-statistic-left__likes"},[_c('span',{staticClass:"likes"},[_vm._v("likes")]),_vm._v(" "),_c('span',{class:[
                'likes-count',
                { 'access-denied' : !_vm.isAccessAllowed } ]},[_vm._v(_vm._s(_vm.validateAccess(_vm.likes.totalLikes)))])])]),_vm._v(" "),_c('div',{staticClass:"dashboard-statistic__separator"}),_vm._v(" "),_c('div',{staticClass:"dashboard-statistic__right dashboard-statistic-right"},[_c('p',{staticClass:"dashboard-statistic-right__comming-soon-text"},[_vm._v("\n            more info soon\n          ")])])]),_vm._v(" "),_c('account-franchisor-dashboard-card',{staticClass:"dashboard__chart dashboard-chart"},[_c('div',{staticClass:"dashboard-chart__head dashboard-chart-head"},[_c('h1',{staticClass:"dashboard-chart-head__title"},[_vm._v("\n            Qualified leads\n          ")]),_vm._v(" "),_c('v-select',{staticClass:"dashboard-chart-head__filter",attrs:{"clearable":false,"options":_vm.monthList,"searchable":false,"with-custom-selected-label":true,"label":"title"},on:{"input":_vm.fillData},model:{value:(_vm.chosenMonth),callback:function ($$v) {_vm.chosenMonth=$$v},expression:"chosenMonth"}})],1),_vm._v(" "),_c('div',{class:[
            'dashboard-chart__content',
            { 'dashboard-chart-content--loading': _vm.chartDataLoading },
            { 'access-denied' : !_vm.isAccessAllowed } ]},[_c('base-bar-chart',{staticStyle:{"width":"100%","height":"100%"},attrs:{"chart-data":_vm.chartData.dataCollection}})],1)]),_vm._v(" "),_c('div',{staticClass:"dashboard__qualified-leads-conversion qualified-leads-conversion"},[_c('account-franchisor-dashboard-card',{staticClass:"qualified-leads-conversion__qualified-leads  qualified-leads"},[_c('div',{staticClass:"qualified-leads__info qualified-leads-info"},[_c('h1',{staticClass:"qualified-leads-info__title"},[_vm._v("\n              Qualified Leads Ratio\n            ")]),_vm._v(" "),_c('span',{class:[
                'qualified-leads-info__progress',
                { 'access-denied' : !_vm.isAccessAllowed } ]},[_c('span',{staticClass:"qualified-leads-info__svg"},[_c('svg',{attrs:{"fill":"none","height":"9","viewBox":"0 0 8 9","width":"8","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M3.68183 9.00001C3.96943 9.00001 4.20257 8.76687 4.20257 8.47927L4.20257 1.99223L6.20936 3.91159C6.41809 4.11122 6.74697 4.11122 6.9557 3.91159C7.17801 3.69897 7.17801 3.34386 6.9557 3.13125L3.68183 1.49369e-05L0.407956 3.13125C0.185653 3.34386 0.185654 3.69897 0.407956 3.91159C0.616686 4.11122 0.945567 4.11122 1.1543 3.91159L3.16109 1.99223L3.16109 8.47927C3.16109 8.76687 3.39423 9.00001 3.68183 9.00001Z","fill":"#00A769"}})])]),_vm._v("\n              "+_vm._s(_vm.validateAccess(_vm.chartData.leadsRatio.delta))+"%\n            ")])]),_vm._v(" "),_c('div',{class:[
              'qualified-leads__chart',
              { 'access-denied' : !_vm.isAccessAllowed } ]},[_c('circle-progress',{attrs:{"loading":_vm.chartDataLoading,"percent":_vm.validateAccess(_vm.chartData.leadsRatio.rate)}})],1)]),_vm._v(" "),_c('account-franchisor-dashboard-card',{staticClass:"qualified-leads-conversion__conversion  conversion"},[_c('div',{staticClass:"conversion__info conversion-info"},[_c('h1',{staticClass:"conversion-info__title"},[_vm._v("\n              Conversion Rate\n            ")]),_vm._v(" "),_c('span',{class:[
                'conversion-info__progress',
                { 'access-denied' : !_vm.isAccessAllowed } ]},[_c('span',{staticClass:"qualified-leads-info__svg"},[_c('svg',{attrs:{"fill":"none","height":"9","viewBox":"0 0 8 9","width":"8","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M3.68183 9.00001C3.96943 9.00001 4.20257 8.76687 4.20257 8.47927L4.20257 1.99223L6.20936 3.91159C6.41809 4.11122 6.74697 4.11122 6.9557 3.91159C7.17801 3.69897 7.17801 3.34386 6.9557 3.13125L3.68183 1.49369e-05L0.407956 3.13125C0.185653 3.34386 0.185654 3.69897 0.407956 3.91159C0.616686 4.11122 0.945567 4.11122 1.1543 3.91159L3.16109 1.99223L3.16109 8.47927C3.16109 8.76687 3.39423 9.00001 3.68183 9.00001Z","fill":"#00A769"}})])]),_vm._v("\n              "+_vm._s(_vm.validateAccess(_vm.chartData.conversion.delta))+"%\n            ")])]),_vm._v(" "),_c('div',{class:[
              'conversion__chart',
              { 'access-denied' : !_vm.isAccessAllowed } ]},[_c('circle-progress',{attrs:{"loading":_vm.chartDataLoading,"percent":_vm.validateAccess(_vm.chartData.conversion.rate)}})],1)])],1)],1)]),_vm._v(" "),_c('template',{slot:"right"},[_c('div',{staticClass:"dashboard-right"},[_c('import-lead',{staticClass:"import-lead"}),_vm._v(" "),_c('account-franchisor-stepper',{attrs:{"step-list":_vm.stepGroupsWithSteps}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }