//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {COMPANY_PHONE_NUMBER, MASK_PHONE} from "@/store/constants"
import StringMask from "string-mask"

export default {
  name: 'PrivacyPolicyPage',
  computed:{
    getFormatedPhone(){
      return (new StringMask(MASK_PHONE)).apply(COMPANY_PHONE_NUMBER)
    },
  },
  head() {
    const title = 'Privacy Policy';
    const descr = 'Concerned about the safety of the data you submit to Franchise123? Learn about our privacy policy and how we protect your details';
    const keywords = '';
    return {
      title: title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ]
    }
  },
};
