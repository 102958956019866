//
//
//
//
//
//
//

export default {
  name: 'CompanyPageRedirect',
  async asyncData({$axios, route, error}) {
    let redirectUrl = ''
    try {
      const franchiseResponse = await $axios.get(
          `/api/v1/business/${route.params.company}/data/latest/`
      );
      const arrData = franchiseResponse.data.data[0]
      const franchise = {
        slug_industry: arrData.slug_industry.attribute_value_string,
        slug: arrData.slug,
      };
      redirectUrl = `/franchise-directory/industries/${franchise.slug_industry}/${franchise.slug}/`;
    } catch (e) {
      error({ statusCode: 404, message: 'Page not found' })
      console.log(e)
      return false
    }
    return {
      redirectUrl,
    }
  },
  created() {
    this.$router.push(this.redirectUrl)
  }
};
