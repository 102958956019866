//
//

import { mapGetters } from "vuex";

export default {
  layout: 'empty',
  computed: mapGetters({
    token: 'auth/token',
    expires: 'auth/expires',
    user: 'auth/user',
  }),
  validate({ query }) {
    return query.token && query.remember && query.expires
  },
  async mounted () {
    if (this.token && this.token !== this.$route.query.token) {
      await this.$store.dispatch('auth/logout')
    }
    
    await this.fetchUser()

    const redirectUri = this.$route.query.redirect_uri ?? '/'
    await this.$router.push(`/${redirectUri}`.replaceAll('//', '/'))
  },
  methods: {
    async fetchUser() {
      await this.$store.dispatch('auth/saveToken', {
        token: this.$route.query.token,
        remember: Boolean(this.$route.query.remember),
        expires: Number(this.$route.query.expires),
      });

      await this.$store.dispatch('auth/fetchUser');
    }
  }
}
