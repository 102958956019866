//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data: () => ({
    isOpen: undefined,
    search: "",
    glossaryContent: [
      {
        id: 0,
        title: "A",
        capital: true
      },
      {
        id: 1,
        title: "Advertising Fund",
        descr:
          "A collective pool of funds used by the franchisor to market the brand. Often, a monthly contribution to the ad fund will be paid by the franchisees alongside the other royalties."
      },
      {
        id: 2,
        title: "Area Franchisee",
        descr:
          "A franchisee who has acquired exclusive rights to open franchise units within a defined territory, usually on a schedule or timeline set at the time of signing an agreement."
      },
      {
        id: 3,
        title: "Area Representative",
        descr:
          "A franchisee who also acts as a salesperson for the franchisor in a specific territory. The area representative identifies new franchisees, but the actual franchise agreement and exchange of funds occurs between the new franchisee and the corporate entity. The area representative may receive a commission afterward from the franchisor."
      },
      {
        id: 4,
        title: "B",
        capital: true
      },
      {
        id: 5,
        title: "Breakeven",
        descr:
          "The point at which a franchise (or any business) takes in enough revenue to balance the investment costs. In other words, the point where it reaches a net profit and net loss of $0."
      },
      {
        id: 6,
        title: "C",
        capital: true
      },
      {
        id: 7,
        title: "Candidate",
        descr:
          "The term used by franchisors to refer to prospective franchisees who have contacted them about their franchise opportunity."
      },
      {
        id: 8,
        title: "Churn",
        descr:
          "The turnover of ownership of a franchisee from one franchisee to another, from a franchisee to the corporate entity, or the termination and closing of a franchise altogether."
      },
      {
        id: 9,
        title: "Company-Owned Locations",
        descr:
          "Also referred to as corporate locations or units, a company-owned location is owned and operated by the corporate entity of the brand, as opposed to by a franchisee."
      },
      {
        id: 10,
        title: "Conversion",
        descr:
          "The “rebranding” and modification of an existing business into a franchise unit of a different company. Some franchisors prefer conversions to new businesses as a way to reduce costs and ensure the franchise owner has the appropriate skills to run the business."
      },
      {
        id: 11,
        title: "Corporate Location",
        descr:
          "Also referred to as a company-owned location or unit, a corporate location is owned and operated by the corporate entity of the brand, as opposed to by a franchisee."
      },
      {
        id: 12,
        title: "D",
        capital: true
      },
      {
        id: 13,
        title: "Discovery Days",
        descr:
          "A term commonly used to refer to the time when a franchisor invites a prospective franchisee (sometimes several at once) to the corporate office to meet the staff and learn more about the company. This is often one of the final steps before the prospective franchisee makes a final decision on investing in the franchise."
      },
      {
        id: 14,
        title: "F",
        capital: true
      },
      {
        id: 15,
        title: "Field Consultant",
        descr:
          "Employee or contract worker of the franchisor whose responsibility it is to support and assist franchisees in the field, at their locations. Usually, field consultants are assigned a geographic region, but this may vary based on size of the franchise system, business model or other factors."
      },
      {
        id: 16,
        title: "Franchise Broker",
        descr:
          "A person or company hired by a franchisor to help cultivate potential new franchisees. Most brokers work with several franchise brands concurrently, and will match a prospective franchisee with the brand that is the best fit based on a set of criteria."
      },
      {
        id: 17,
        title: "Franchise Development",
        descr:
          "The “sales” process of adding new franchisees to a franchise company. Staff with “development” in their title are typically charged with bringing new franchisees on board; however, the most successful franchise brands generally treat this process less as a sale and more as a job interview. They should be looking for the right fit for them, and you as a potential franchisee."
      },
      {
        id: 18,
        title: "Franchise Expo",
        descr:
          "Event in which prospective franchisees can meet with a number of franchise companies in person to discuss the opportunities they offer. The largest expos in the U.S. take place in New York City, Anaheim, and Houston each year, and are hosted by MFV Expositions."
      },
      {
        id: 19,
        title: "Franchise Agreement",
        descr:
          "The contract a franchisor and franchisee sign to confirm the agreement to open one or more franchise business(es). Among other details, the franchise agreement will include a term, typically ranging from 5 to 20 years, that the franchisee is agreeing to continuously own the unit(s) being purchased."
      },
      {
        id: 20,
        title: "Franchise Disclosure Document (FDD)",
        descr:
          "A standardized document required in the U.S. for all companies offering a franchise opportunity. The FDD is a lengthy document that contains detailed information on the franchise, including a description of the business model, estimated costs of starting a franchise, names of officers and franchise owners, and other information."
      },
      {
        id: 21,
        title: "Franchise Fee",
        descr:
          "A component of the initial investment in a franchise business that allows the franchisee to use the franchise brand’s name and likeness. This is a one time fee."
      },
      {
        id: 22,
        title: "Franchisee",
        descr:
          "The name given to a person or corporate entity that owns a franchise business."
      },
      {
        id: 23,
        title: "Franchisee Satisfaction Index (FSI)",
        descr:
          "A measurement of the satisfaction of franchise owners within a brand. FSI was created by Franchise Business Review in 2007 and is represented on a 100-point scale."
      },
      {
        id: 24,
        title: "Franchisor",
        descr:
          "The name given to a company that offers a franchise opportunity as a means of growth. Sometimes referred to as “franchisor.”"
      },
      {
        id: 25,
        title: "I",
        capital: true
      },
      {
        id: 26,
        title: "Initial Investment",
        descr:
          "The estimated total investment a franchisee will need to get the franchise business up and running. Usually represented as a range showing a low-end and high-end, the initial investment can be found in Item 7 of a franchisor’s Franchise Disclosure Document. Cost elements will include the franchisee fee, equipment, property lease, and/or other ramp-up costs."
      },
      {
        id: 27,
        title: "International Franchise Association (IFA)",
        descr:
          "The largest and best-known organization representing the franchising industry. The IFA works to provide resources to franchisors, franchisees, and suppliers to franchise companies and is active in the political space for franchise and small business interest."
      },
      {
        id: 28,
        title: "Item 19",
        descr:
          "The section of the Franchise Disclosure Document that a franchisor may use to disclose earnings claims of existing franchise owners and corporate locations. Note that this data is not a mandatory inclusion in the FDD, and the data provided may represent only a specific group of franchisees and/or corporate-owned franchises. Always read the fine print to understand where the numbers come from, especially if comparing Item 19 claims from several brands."
      },
      {
        id: 29,
        title: "L",
        capital: true
      },
      {
        id: 30,
        title: "Lender",
        descr:
          "A bank or financial institution that provides a loan, in this case referring to a business loan."
      },
      {
        id: 31,
        title: "Liquid Capital",
        descr:
          "A sum of cash and other assets that can be easily converted to cash. Franchisors will require a specific minimum amount of available liquid capital from prospective franchisees."
      },
      {
        id: 32,
        title: "Low-Cost Franchise",
        descr:
          "A franchise with a low initial investment, typically defined as being under $100,000."
      },
      {
        id: 33,
        title: "M",
        capital: true
      },
      {
        id: 34,
        title: "Master Franchise",
        descr:
          "A franchise agreement in which the franchisor agrees to allow a franchisee to sell franchise units in a specific geographic region. A Master Franchisee may, but doesn’t necessarily own one or more franchises in their allotted territory."
      },
      {
        id: 35,
        title: "Multi-Concept Franchisee",
        descr:
          "A franchisee who owns units of multiple different franchise brands. Some franchise brands prohibit multi-concept franchising for their franchisees, while others may actively seek franchisees who already own other brands."
      },
      {
        id: 36,
        title: "Multi-Unit Franchisee",
        descr:
          "A franchisee who owns multiple franchise business units. Often, this refers to units of the same brand, but may also refer to “multi-concept” ownership."
      },
      {
        id: 37,
        title: "N",
        capital: true
      },
      {
        id: 38,
        title: "Net Worth",
        descr:
          "Calculation of one’s total value (total assets minus total liabilities). Many franchise brands require a minimum net worth in addition to a minimum liquid capital for prospective franchisees."
      },
      {
        id: 39,
        title: "O",
        capital: true
      },
      {
        id: 40,
        title: "Operations",
        descr:
          "The processes, procedures, and strategies employed by the business to provide the product and/or services to its customers."
      },
      {
        id: 41,
        title: "R",
        capital: true
      },
      {
        id: 42,
        title: "Renewal",
        descr:
          "Extension of the original franchise agreement whereby the franchisee retains ownership of the franchise business for a new term."
      },
      {
        id: 43,
        title: "Return on Investment (ROI)",
        descr:
          "A percentage of value of a business (or any investment) relative to the cost of establishing it. A $100,000 business investment that is now worth $200,000 would have a 100% ROI. The formula for ROI is [current value - total cost] / [total cost] * 100."
      },
      {
        id: 44,
        title: "Royalties/Royalty Fees",
        descr:
          "The sum of money, usually a percentage of gross sales, paid by the franchisee to the franchisor on a regular (usually monthly) basis as part of the franchise agreement. Typical royalty fees are under 10% of gross sales, but some companies may have higher fees or a different type of fee structure depending on the services/support offered by the franchisor."
      },
      {
        id: 45,
        title: "S",
        capital: true
      },
      {
        id: 46,
        title: "Senior Care",
        descr:
          "Industry sector focused on in-home care for seniors, or services associated with caring for seniors. Includes companies offering non-medical care, with some offering medical services as well. Senior Care has been a very popular and successful segment of the franchise industry in recent years."
      },
      {
        id: 47,
        title: "Single Unit",
        descr:
          "A single franchise business, as opposed to multi-unit ownership, in which one franchisee owns several franchise units."
      },
      {
        id: 48,
        title: "Startup Costs",
        descr:
          "The total initial (and not perpetual) costs that go into starting a franchise business. This can include the franchisee fee, construction fees, equipment purchases, legal fees, and various other costs."
      },
      {
        id: 49,
        title: "Supplier/Vendor",
        descr:
          "A business providing a service or product to another business. Franchisors often establish “preferred” supplier/vendor relationships wherein individual franchises receive negotiated discount pricing."
      },
      {
        id: 50,
        title: "T",
        capital: true
      },
      {
        id: 51,
        title: "Territory",
        descr:
          "A designated area that comprises a franchise “unit,” typically used for service-based or mobile franchise business models. Many franchisors provide exclusive territories to prevent conflict between franchisees."
      },
      {
        id: 52,
        title: "Transfer",
        descr:
          "Ownership of a franchise business is moved from one party to another."
      },
      {
        id: 53,
        title: "Turnover",
        descr:
          "Refers to a franchise agreement that has been terminated, not renewed, transferred, or the franchise business goes out of business."
      },
      {
        id: 54,
        title: "U",
        capital: true
      },
      {
        id: 55,
        title: "UFOC",
        descr:
          "A Uniform Franchise Offering Circular (UFOC) is the original name of what is now called the FDD (Franchise Disclosure Document)."
      },
      {
        id: 56,
        title: "V",
        capital: true
      },
      {
        id: 57,
        title: "Validation",
        descr:
          "Part of “due diligence” when buying a franchise. Calling to speak with existing franchise owners in an attempt to validate the virtues of the franchise opportunity as explained by the franchisor. Typically, the prospective franchisee will contact several franchisees from the list provided in the company’s FDD."
      }
    ]
  }),
  computed: {
    filteredList() {
      return this.glossaryContent.filter(item => {
        return item.title.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    toggle(item) {
      if (this.isOpen == item) {
        this.isOpen = undefined;
      } else {
        this.isOpen = item;
      }
    }
  },
  head() {
    const title = 'Franchise Glossary & Important Terms';
    const descr = 'Unsure about common terms related to franchising? Check out our franchise glossary with must-know terms and definitions for franchisees.';
    const keywords = '';
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ]
    }
  },
};
