import { render, staticRenderFns } from "./confirm-change.vue?vue&type=template&id=c462899c&scoped=true&"
import script from "./confirm-change.vue?vue&type=script&lang=js&"
export * from "./confirm-change.vue?vue&type=script&lang=js&"
import style0 from "./confirm-change.vue?vue&type=style&index=0&id=c462899c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c462899c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/root/repo/components/Loader.vue').default,VButton: require('/root/repo/components/VButton.vue').default})
