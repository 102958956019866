//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FranchiseeStepsCard from "@/components/cards/FranchiseeStepsCard";
import ArrowBack from "@/components/icons/ArrowBack";
import BaseModal from "@/components/modals/BaseModal";
import BaseModalV2 from "@/components/modals/BaseModalV2";
import {
  STATUS_IN_PROGRESS,
  DOCUMENT_TYPE_DEFAULT,
  DOCUMENT_TYPE_VIDEO,
  DOCUMENT_TYPE_LINK,
  DOCUMENT_TYPE_PRESENTATION,
}
  from '@/store/constants';
import CloseIcon from "@/components/icons/CloseIcon";

export default {
  name: 'FranchiseStepsIndexPage',
  middleware:[
    'accountFranchisee'
  ],
  components: {
    BaseModal,
    BaseModalV2,
    FranchiseeStepsCard,
    ArrowBack,
    CloseIcon,
  },
  async asyncData({query, $axios, store, error}) {
    const franchiseSlug = query.slug;

    await store.dispatch('franchisee-steps/getOrCreateSteps', franchiseSlug);
    const stepList = store.getters['franchisee-steps/businessClientSteps'];

    let franchise;
    let franchiseName;
    let franchiseUrl;
    try {
      const franchiseResponse = await $axios.get(
        `/api/v1/business/${stepList[0].franchise_id}/data/latest/`
      );
      franchise = franchiseResponse.data.data[0];
      franchiseName = franchise.business_name.attribute_value_string;
      franchiseUrl = `/franchise-directory/industries/${franchise.slug_industry.attribute_value_string}/${franchise.slug}`;
    } catch (e) {
      error({statusCode: 404, message: 'Page not found'})
      console.log(e)
      return false
    }

    return {
      franchiseSlug,
      stepList,
      franchiseName,
      franchiseUrl,
    };
  },
  data() {
    return {
      STATUS_IN_PROGRESS,
      DOCUMENT_TYPE_DEFAULT,
      DOCUMENT_TYPE_VIDEO,
      DOCUMENT_TYPE_LINK,
      DOCUMENT_TYPE_PRESENTATION,
      alertModalVisible: false,
      showVideoPopup: false,
      videoPopupUrl: '',
    };
  },
  computed: {
    inProgressStepIndex() {
      const inProgressIndex = this.stepList.findIndex((item) => {
        return item.status === this.STATUS_IN_PROGRESS;
      });
      if (inProgressIndex === -1) {
        return 0;
      }
      return inProgressIndex + 1;
    },
  },
  methods: {
    closeVideoPopup() {
      this.showVideoPopup = false
      this.videoPopupUrl = ''
    },
    /**
     * Document link click handler
     * @param {number} docIndex
     * @param {number} stepIndex
     */
    handleClickDocument(docIndex, stepIndex) {
      let previousDocsPassed = true;

      for (let i = stepIndex - 1; i >= 0; i -= 1) {
        if (!previousDocsPassed) break;

        const previousStep = this.stepList[i];
        for (let j = 0; j < previousStep.docs.length; j += 1) {
          if (
            !previousStep.docs[j].is_downloaded &&
            previousStep.docs[j].doc_subtype === 'presentation'
          ) {
            previousDocsPassed = false;
            break;
          }
        }
      }
      // if all presentation documents passed
      if (previousDocsPassed) {
        const doc = this.stepList[stepIndex].docs[docIndex];
        this.$store.commit('franchisee-steps/SET_AS_DOCUMENT_DOWNLOADED', {
          stepIndex,
          docIndex,
        });
        this.openDocument(doc);
      } else {
        this.showMessageAlert();
      }
    },
    /**
     * Open document new page
     * @param doc
     */
    async openDocument(doc) {
      const url = `${process.env.BACKEND_BASE_URL}/api/v1/business-step-docs/download/${doc.step_doc_id}`
      if (doc.doc_type === this.DOCUMENT_TYPE_DEFAULT) {
        window.open(
          url,
          '_blank'
        );
      } else if (doc.doc_type === this.DOCUMENT_TYPE_LINK) {
        this.$axios.get(`${url}?return_file=false`)
        if (doc.doc_subtype === this.DOCUMENT_TYPE_VIDEO || doc.doc_subtype === this.DOCUMENT_TYPE_PRESENTATION) {
          this.videoPopupUrl = doc.link;
          this.showVideoPopup = true;
        } else {
          window.open(doc.link, '_blank');
        }
      }
    },
    /**
     * Show permission alert
     */
    showMessageAlert() {
      this.alertModalVisible = true;
    },
  },
  head() {
    const title = `Franchisee Steps | ${this.franchiseName}`
    return {
      title,
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
      ]
    };
  },
};
