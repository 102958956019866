import { render, staticRenderFns } from "./account.vue?vue&type=template&id=8907ad7a&scoped=true&"
import script from "./account.vue?vue&type=script&lang=js&"
export * from "./account.vue?vue&type=script&lang=js&"
import style0 from "./account.vue?vue&type=style&index=0&id=8907ad7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8907ad7a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/root/repo/components/Loader.vue').default})
