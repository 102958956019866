//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from "vuex"
import PaginationDefault from "@/components/paginations/PaginationDefault"
import ContactActionTaskCard from "@/components/account-franchisor/contacts/actions/tasks/ContactActionTaskCard"
import ActionsDateRangePicker from "@/components/account-franchisor/range/ActionsDateRangePicker"
import ContactActionTaskEdit from "@/components/account-franchisor/contacts/actions/tasks/ContactActionTaskEdit"
import ClearIcon from "@/components/icons/ClearIcon"
import MultiSelect from "vue-multiselect";
import {
  ACCOUNT_FRANCHISOR_TASK_STATUS_LIST,
} from "@/store/constants"
import Check from "~/assets/icons/check/Check.svg?inline"
import ArrowIcon from '~/components/icons/Arrow'

import storeMixin from "~/components/mixins/storeMixin"
import pagesMixin from "~/components/mixins/pagesMixin"

export default {
  name: "Tasks",
  components: {
    PaginationDefault,
    ContactActionTaskCard,
    ActionsDateRangePicker,
    ContactActionTaskEdit,
    MultiSelect,
    Check,
    ArrowIcon,
    ClearIcon
  },
  mixins: [
    storeMixin,
    pagesMixin,
  ],
  data() {
    return {
      showEdit: false,
      isCreateMode: false,
      currentTask: {},
      selectedStatus:[],
      statusList: ACCOUNT_FRANCHISOR_TASK_STATUS_LIST,
    }
  },
  computed: {
    ...mapGetters({
      contacts: 'account-franchisor/contacts/list',
      taskListArr: 'account-franchisor/all-tasks/taskListArr',
      paginationObj: 'account-franchisor/all-tasks/paginationObj',
      filterObj: 'account-franchisor/all-tasks/filterObj',
      filterStatus: 'account-franchisor/all-tasks/filterStatus',
      busy: 'account-franchisor/all-tasks/busy',
    }),
    filterOptions(){
      return this.statusList.map(item => ({title:item.text, value:item.value, id:item.value}))
    },
    filterStatusOptionsSelected() {
      if (!this.selectedStatus) return []

      const result = []
      this.selectedStatus.forEach(el => result.push(el.value))
      return result
    },
    franchiseeId() {
      return +this.currentTask.franchisee_id ?? 0
    },
    modalTitle() {
      return this.isCreateMode ? 'Create task' : 'Edit task'
    },
  },
  mounted() {
    this.fetchTasks()
    this.fetchContactsList()
  },
  methods: {
    ...mapActions({
      fetchContactsList: 'account-franchisor/contacts/fetchContactsList',
      fetchTasks: 'account-franchisor/all-tasks/fetchTasks',
      changePage: 'account-franchisor/all-tasks/changePage',
      updateFilter: 'account-franchisor/all-tasks/updateFilter',
      updateFilterStatus: 'account-franchisor/all-tasks/updateFilterStatus',
    }),
    selectAll() {
      if (this.selectedStatus !== this.filterOptions)
        this.selectedStatus = this.filterOptions
      else
        this.selectedStatus = null
    },
    handlerUpdateStatus(){
      this.updateFilterStatus(this.filterStatusOptionsSelected)
      this.handlerChangePage(1)
      this.fetchTasks()
    },
    handlerSavedEvent(){
      this.showEdit = false
      this.fetchTasks()
    },
    async handlerChangeDatePicker({startDate, endDate}) {
      const filterStartDate = startDate === null? startDate: new Date(startDate).toISOString()
      const filterEndDate = endDate === null? endDate: new Date(endDate).toISOString()

      await this.updateFilter({startDate:filterStartDate, endDate:filterEndDate})
      await this.fetchTasks()
    },
    handlerCreateNewTask() {
      this.currentTask = {}
      this.isCreateMode = true
      this.showEdit = true
    },
    async handlerChangeEdit(task) {
      this.isCreateMode = false
      this.currentTask = task
      this.showEdit = true
    },
    async handlerChangeDelete(task) {
      const id = task.id

      try {
        await this.$axios.delete(`api/v2/franchisor-contacts/tasks/delete/${id}`)
        await this.fetchTasks()
      } catch (e) {
        console.log(e)
      }
    },
    handlerChangePage(page) {
      this.changePage(page)
      this.fetchTasks()
    },
  }
}
