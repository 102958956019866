//
//
//
//

export default {
  name:'PageAccountFranchisor',
  middleware:[
    'accountFranchisor'
  ],
  async asyncData({ store, redirect, route, query, error, $axios }) {
    let franchiseId = null
    let versionId = null

    const user = store.getters['auth/user']

    if (user.isMasterAdmin) {
      franchiseId = query.franchise_id
      versionId = query.version_id

      await store.dispatch('account-franchisor/fetchVersionInfo', { versionId })
      const owner = store.getters['account-franchisor/ownerData']

      if (owner) {
        await store.dispatch('payment/fetchPayment', owner.id)
      }
    } else {
      await store.dispatch('account-franchisor/fetchVersionInfo')

      const paymentStatus = query.payment_status;
      const paymentSessionId = query.session_id;
      if (paymentStatus === 'success' && paymentSessionId !== null) {
        try {
          await $axios.post('/api/v1/payment/retrieve', { sessionId: paymentSessionId });
        } catch (e) {
          console.log(e);
        }
      }

      let paymentUser = store.getters['payment/user']
      if (paymentUser === null) {
        const owner = store.getters['account-franchisor/ownerData']
        await store.dispatch('payment/fetchPayment', owner.id)
        paymentUser = store.getters['payment/user']
      }

      if (!paymentUser.membership_level) {
        redirect('/membership')
      }

      if (!paymentUser.signed_contract) {
        if (paymentUser.expired) {
          await store.dispatch('auth/logout');
          redirect('/?membershipExpired=yes')
        }

        if (route.path.includes('chat') && route.params.slug) {
          error({ statusCode: 403 })
        }
      }
    }

    franchiseId = store.getters['account-franchisor/franchiseId']
    versionId = store.getters['account-franchisor/versionId']
    if (!franchiseId || !versionId) {
      error({ statusCode: 404 })
    }

    if (!store.getters['account-franchisor/versionApproved']) {
      if (route.path.includes('chat') || route.path.includes('steps')) {
        error({ statusCode: 403 })
      }

      if (user.isFranchisor && route.path.includes('budget')) {
        error({ statusCode: 403 })
      }
    }

    await store.dispatch('business-v2/fetchFranchise', { franchiseId, versionId })
    if (!store.getters['business-v2/franchise']) {
      error({ statusCode: 404 })
    }
  }
}
