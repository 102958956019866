//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from 'vuex'
import {cloneDeep} from "lodash/fp"
import MultiSelect from "vue-multiselect"
import ClearIcon from "@/components/icons/ClearIcon"
import {MASK_PHONE, USER_PREFERENCES_STATE} from "@/store/constants"
import StringMask from "string-mask"
import BaseButton from "~/components/buttons/BaseButton"
import SilentMode from "~/components/account-franchisor/SilentMode"
import PaginationDefault from "~/components/paginations/PaginationDefault"
import FindChat from "~/components/account-franchisor/inputs/FindChat"
import VSelect from "~/components/form/VSelect"
import Check from "~/assets/icons/check/Check.svg?inline"
import FilterIcon from "~/assets/images/icons/filter.svg?inline"
import CloseIcon from "~/assets/images/sign-up/close.svg?inline"
import ArrowIcon from '~/components/icons/Arrow'
import NewLeadForm from "~/components/form/accountFranchisor/NewLeadForm"


import {
  MODAL_VIEW_FRANCHISEE_INFO,
  USER_PREFERENCES_TOTAL_AMOUNT_INVEST,
} from '~/store/constants'

import storeMixin from "~/components/mixins/storeMixin"

export default {
  name: 'FranchisorAccountContactIndexPage',
  components: {
    NewLeadForm,
    BaseButton,
    VSelect,
    FindChat,
    PaginationDefault,
    SilentMode,
    MultiSelect,
    Check,
    ArrowIcon,
    FilterIcon,
    CloseIcon,
    ClearIcon,
  },
  mixins: [
    storeMixin,
  ],
  data() {
    return {
      showMobFilters: false,
      showNewLeadModal: false,
      fields: [
        {
          key: 'full_name',
          label: 'Name',
          stickyColumn: true,
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'phone',
          label: 'Phone number',
        },
        {
          key: 'active_step_name',
          label: 'Active step',
        },
        {
          key: 'preferences_industry',
          label: 'Industry',
        },
        {
          key: 'preferences_state_id',
          label: 'State',
        },
        {
          key: 'preferences_total_amount_invest',
          label: 'Investment',
        },
        {
          key: 'start_date',
          label: 'Start date',
        },
        {
          key: 'last_activity',
          label: 'Last activity',
        },
        {
          key: 'id',
          label: 'Actions',
        },
      ],
      counter: 0,
      debounceChatFind: null,
      contactsFilter: {
        search: null,
        activeStep: null,
        filter: null,
        filterPrev: null,
        preferences: {
          industry: null,
          state_id: null,
          total_amount_invest: null,
        },
      },
      contactsFilterOptions: [
        {
          title: 'Active',
          value: 'active',
        },
        {
          title: 'Silent',
          value: 'silent',
        },
        {
          title: 'Engaged',
          value: 'engaged',
        },
        {
          title: 'Pipeline',
          value: 'pipeline',
        },
      ],
      investmentDirectory: USER_PREFERENCES_TOTAL_AMOUNT_INVEST,
    }
  },
  computed: {
    ...mapGetters({
      statesDirectory: 'directories/statesOptions',
      industriesDirectory: 'directories/industries',
      versionApproved: 'account-franchisor/versionApproved',
      paymentPermissions: 'payment/permissions',
      businessStepsList: 'business-steps/businessStepsList',
      businessStepsListWithoutCounter: 'business-steps/businessStepsListWithoutCounter',
      franchise: 'business-v2/franchise',
      paginationObj: 'account-franchisor/contacts/listPagination',
      contacts: 'account-franchisor/contacts/list',
      busy: 'account-franchisor/contacts/busy',
      user: 'auth/user',
    }),
    contactsFilterOptionsSelected() {
      if (!this.contactsFilter.filter) return []

      const result = []
      this.contactsFilter.filter.forEach(el => result.push(el.value))
      return result
    },
    contactsActiveStepOptionsSelected() {
      if (!this.contactsFilter.activeStep) return []

      const result = []
      this.contactsFilter.activeStep.forEach(el => result.push(el.value))
      return result
    },
    totalLeads() {
      if (
        this.businessStepsList &&
        Object.keys(this.businessStepsList).length
      ) {
        return this.businessStepsList[0].count_franchisee
      }
      return 0
    }
  },
  watch: {
    contactsFilter: {
      handler() {
        if (this.debounceChatFind !== null)
          clearTimeout(this.debounceChatFind)

        this.debounceChatFind = setTimeout(async () => {
          await this.getData('1')
          clearTimeout(this.debounceChatFind)
          this.debounceChatFind = null
        }, 1000)
      },
      deep: true
    },
  },
  async mounted() {
    await this.checkPermissions()
    await this.getData()
    await this.fetchBusinessSteps({franchiseId: this.franchise.id})
    await this.fetchIndustries()
    await this.fetchStates()
  },
  methods: {
    ...mapActions({
      fetchBusinessSteps: 'business-steps/fetchBusinessSteps',
      updateFranchiseeViewedId: 'account-franchisor/updateFranchiseeViewedId',
      fetchContactsList: 'account-franchisor/contacts/fetchContactsList',
      fetchStates: "directories/fetchStates",
      fetchIndustries: "directories/fetchIndustries",
    }),
    getPreferences(preferences, type) {
      let prefArr = preferences.filter(item => typeof item[type] !== "undefined")
      prefArr = prefArr.map(item => item[type])

      if (type === 'state_id') {
        prefArr.sort()
      }

      const limit = 5
      let i = 0
      let result = ''
      prefArr.forEach(item => {
        i += 1
        if (i > limit)
          return

        let value = item

        if (type === 'state_id') {
          const currentState = USER_PREFERENCES_STATE.find(state => state.name.toLowerCase() === value.toLowerCase())

          if (currentState !== undefined)
            value = `${currentState.name}, ${currentState.code}`

        }

        result += `<span>${value}</span>`
      })


      if (i > limit) {
        result += `<span>+ ${i - limit} more</span>`
      }

      return result
    },
    openNewLeadModal() {
      this.showNewLeadModal = true
    },
    closeNewLeadModal() {
      this.showNewLeadModal = false
    },
    setFilterStep(step) {
      this.contactsFilter.activeStep = [cloneDeep(step)]
    },
    async checkPermissions() {
      const permissionType = this.paymentPermissions.CRM
      let allowAction = true
      let message = ''

      if (!this.versionApproved) {
        allowAction = false
        message = this.$t('accountFranchisor.accessMessages.notApprovedFranchisor')
      }

      if (!permissionType.access) {
        allowAction = false
        message = permissionType.message
      }

      if (!allowAction) {
        await this.fireModalMessage({message})
        await this.$router.push('/account-franchisor/dashboard/')
      }
    },
    fullNameUpdate(name) {
      return name.replaceAll(' ', ' <br class="mobile-br"/>')
    },
    updateMobFilter(option) {
      if (this.contactsFilter.filter === null) {
        this.contactsFilter.filter = []
      }
      const issetIndex = this.contactsFilterOptionsSelected.indexOf(option.value)
      if (issetIndex !== -1) {
        this.contactsFilter.filter.splice(issetIndex, 1)
      } else {
        this.contactsFilter.filter.push(option)
      }
    },
    toggleMobFilters() {
      this.showMobFilters = !this.showMobFilters
    },
    selectAll() {
      if (this.contactsFilter.filter !== this.contactsFilterOptions)
        this.contactsFilter.filter = this.contactsFilterOptions
      else
        this.contactsFilter.filter = null
    },
    handleClickOpenModal(contact) {
      if (!this.paymentPermissions.franchiseeProfile.access) {
        this.fireModalMessage({message: this.paymentPermissions.franchiseeProfile.message})
        return
      }

      this.updateFranchiseeViewedId({
        id: contact.id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        full_name: contact.full_name,
        initials: contact.initials,
        silent_account: contact.silent_account,
        franchisee_id: contact.id,
      })
      this.$bvModal.show(MODAL_VIEW_FRANCHISEE_INFO)
    },
    getValue(value, defaultValue = '-') {
      return !value || value === '' ? defaultValue : value
    },
    getValuePhone(value) {
      if (value === null)
        return this.getValue(value)

      return (new StringMask(MASK_PHONE)).apply(value.replace(/[^0-9]/g, ""))
    },
    async getData(page = 1) {
      const search = this.contactsFilter.search
      const activeStep = this.contactsActiveStepOptionsSelected

      const filter = this.contactsFilterOptionsSelected
      const filterPrev = this.contactsFilter.filterPrev

      const preferences = this.contactsFilter.preferences

      this.fetchContactsList({
        search,
        activeStep,
        filter,
        preferences,
        page,
      })

      if (filter && filter !== filterPrev) {
        await this.fetchBusinessSteps({
          franchiseId: this.franchise.id,
          filter,
        })
      }
      this.contactsFilter.filterPrev = filter
    },
    async handleChangePage(pageNum) {
      await this.getData(pageNum)
    },
    openContact(id) {
      if (this.user.isFranchisor) {
        this.$router.push(this.buildAccountFranchisorUri(`/account-franchisor/contacts/${id}`))
      }
      if (this.user.isMasterAdmin) {
        window.open(`/admin/franchisees/${id}`, `_blank`).focus()
      }
    },
  }
}
