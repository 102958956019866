//
//

import { mapGetters } from "vuex"

export default {
  name:'AuthLoginPage',
  computed: {
    ...mapGetters({
      show: 'login/show'
    })
  },
  watch: {
    show (newVal) {
      if (!newVal) {
        this.$router.push('/')
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('login/openModal')
  }
}
