//
//
//
//
//
//

import DashboardFranchiseeComparisons from "@/components/dashboards/viewFranchisee/DashboardFranchiseeComparisons";
import {mapActions} from 'vuex';


export default {
  name: 'AccountComparisonsPage',
  components: {
    DashboardFranchiseeComparisons
  },
  mounted() {
    this.fetchComparisonsList()
  },
  created() {
    this.setShowPreloader(true)
  },

  methods: {
    ...mapActions({
      fetchComparisonsList: 'account/fetchComparisonsList',
      setShowPreloader: 'account/setShowPreloader',
    }),
  },
};
