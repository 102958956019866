//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VSelect from "@/components/form/VSelect"
import CircleProgress from "@/components/progress/CircleProgress"
import {mapActions, mapGetters} from 'vuex'
import AccountFranchisorDashboardCard from "~/components/account-franchisor/cards/AccountFranchisorDashboardCard"
import AccountFranchisorLayout from "~/components/account-franchisor/AccountFranchisorLayout"
import AccountFranchisorStepper from "~/components/account-franchisor/AccountFranchisorStepper"
import BaseBarChart from "~/components/charts/BaseBarChart"
import ImportLead from "~/components/account-franchisor/ImportLead"

import {
  ENV_TYPE,
} from '~/store/constants'

export default {
  name: 'PageAccountFranchisorDashboard',
  components: {
    CircleProgress,
    VSelect,
    ImportLead,
    AccountFranchisorDashboardCard,
    AccountFranchisorLayout,
    AccountFranchisorStepper,
    BaseBarChart,
  },
  data: () => ({
    likes: {
      totalLikes: 0,
      likesPerMonth: 0
    },
    chartData: {
      dataCollection: {
        labels: [],
        datasets: [
          {
            barPercentage: 10,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 10,
            data: [],
            hoverBackgroundColor: 'rgba(0,167,105,1)',
            backgroundColor: 'rgba(153,220,195,1)',
            borderWidth: 0,
          },
        ],
      },
      leadsRatio: {
        rate: 0,
        delta: 0
      },
      conversion: {
        rate: 0,
        delta: 0
      }
    },
    chartDataLoading: true,
    monthList: [],
    chosenMonth: null,
    ENV_TYPE,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      franchise: 'business-v2/franchise',
      stepGroupsWithSteps: 'business-steps/businessSteps',
      versionApproved: 'account-franchisor/versionApproved',
      paymentPermissions: 'payment/permissions',
    }),
    isAccessAllowed() {
      return this.user.isMasterAdmin && this.versionApproved ||
        (this.versionApproved && this.user.isFranchisor && this.paymentPermissions.chat.access)
    },
  },
  async mounted() {
    await this.fetchBusinessSteps({franchiseId: this.franchise.id})

    try {
      const {data} = await this.$axios.get('/api/v1/directories/months')
      this.monthList = data.months
      this.chosenMonth = data.current
    } catch (e) {
      console.log(e)
    }

    await this.fillData()
    this.getLikesForFranchise()
  },
  methods: {
    ...mapActions({
      fetchBusinessSteps: 'business-steps/fetchBusinessSteps',
    }),
    validateAccess(value) {
      return this.isAccessAllowed ? value : 0
    },
    getLikesForFranchise() {
      this.likes.totalLikes = this.franchise.total_like
    },
    adaptValue(val) {
      return val || 'N/A'
    },
    firstLetter(word) {
      return word ? word.slice(0, 1) : ''
    },
    showFile(link) {
      if (this.authenticated) {
        this.$store.dispatch('iframe/openModal', link)
      } else {
        this.$store.dispatch('registration/openModal')
      }
    },
    async fillData() {
      if (!this.isAccessAllowed) {
        this.chartDataLoading = false
        return
      }

      let labels = []
      let chartData = []
      let ratio = {rate: 0, delta: 0}
      let conversion = {rate: 0, delta: 0}

      try {
        const {data} = await this.$axios.get(
          `/api/v2/business-client-steps/${this.franchise.id}/stats`, {
            params: {month_year: this.chosenMonth}
          })

        labels = data.result.leads.labels
        chartData = data.result.leads.data
        ratio = data.result.ratio
        conversion = data.result.conversion
      } catch (e) {
        console.log(e)
      }

      this.chartData.dataCollection = {
        labels,
        datasets: [
          {
            barPercentage: 10,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 1,
            data: chartData,
            hoverBackgroundColor: 'rgba(0,167,105,1)',
            backgroundColor: 'rgba(153,220,195,1)',
            borderWidth: 0,
          },
        ],
      }

      this.chartData.leadsRatio = ratio
      this.chartData.conversion = conversion

      this.chartDataLoading = false
    },
    getRandomInt() {
      return Math.floor(Math.random() * (32 - 5 + 1)) + 5
    },
  },
}
