import { render, staticRenderFns } from "./budget.vue?vue&type=template&id=6d974a87&scoped=true&"
import script from "./budget.vue?vue&type=script&lang=js&"
export * from "./budget.vue?vue&type=script&lang=js&"
import style0 from "./budget.vue?vue&type=style&index=0&id=6d974a87&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d974a87",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountFranchisorMobileMenu: require('/root/repo/components/account-franchisor/menu/AccountFranchisorMobileMenu.vue').default,Loading: require('/root/repo/components/Loading.vue').default})
