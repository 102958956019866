//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from "@/components/Loader"

export default {
  layout: 'empty',
  components: {Loader},
  async mounted() {
    await this.$store.dispatch('auth/logout')

    const url = this.$route.query?.redirect ?? '/'

    window.location.replace(url)
  }
}
