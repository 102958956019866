//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data: () => ({
    isOpen: undefined,
    faqAccordionContent: [
      {
        id: 0,
        title: 'What are the roles and responsibilities of the franchisee and franchisor?',
        descr:
          'The role of the franchisor is to provide access to trademarks and proprietary information/products for a royalty fee. As a franchisee, it will be your responsibility to decide how to put in place the systems suggested by the franchisor. Even though you will be in business for yourself, the franchisor will be there to support you.',
      },
      {
        id: 1,
        title: 'Is franchising right for me?',
        descr:
          'When you purchase a franchise, you are in business for yourself but not by yourself. Franchising is ideal for someone who wants to become their own boss, grow their income portfolio, or achieve a supplementary income without having to build a business from scratch. While you may not have as much creative license as a startup business, you will receive the peace of mind that the franchisor’s support provides. If you can follow the franchisor’s proven systems, you are likely to achieve growth and success for your business.',
      },
      {
        id: 2,
        title: 'Do I need business experience prior to purchasing a franchise?',
        descr:
          'While it may be helpful to have some background in business or sales, no experience is necessary to purchase a franchise. The franchisor will provide you with all the information and training you need. As long as you are motivated and diligent, you can become a successful franchise owner.',
      },
      {
        id: 3,
        title: 'What are the benefits of franchising vs. starting up my own business?',
        descr:
          '1. Access to proven and proprietary systems and processes. <br> 2. Less trial and error. <br> 3. More financing options and increased likelihood of approval. <br> 4. Lower build out cost. <br> 5. Less time to grand opening and breakeven. <br> 6. Increased brand recognition and loyalty.',
      },
      {
        id: 4,
        title: 'What is the FDD?',
        descr:
          'The FDD outlines important information regarding the roles and responsibilities of both parties. It provides an honest and comprehensive view of the brand. The FDD is a regulated document mandated by the Federal Trade Commission (FTC) and must be provided to franchisees at least 14 days prior to signing a franchise agreement.',
      },
      {
        id: 5,
        title: 'How do I determine the right franchise opportunity for me?',
        descr:
          'Franchise123 is here to help align franchisee and franchisor vision. Our number one priority is to help you identify and assess which franchise opportunities best suit your lifestyle and goals. Get in touch with us for more information.',
      },
      {
        id: 6,
        title: 'How much does buying into a franchise cost?',
        descr:
          'Franchise fees and initial investments vary based on the industry and specific franchisor. Franchises with a higher start up cost may offer more income streams or potential for ROI so it’s important to do your due diligence when selecting a franchise. Initial investment ranges for each franchisor can be found in the brand’s FDD.',
      },
      {
        id: 7,
        title: 'How can I fund my franchise?',
        descr:
          'Financing options may include SBA loans, 401K/IRA Programs, and Home Equity Line of Credit. Franchise Accelerators can put you in touch with our strategic partners to help you determine the best funding plan for your franchise.',
      },
      {
        id: 8,
        title: 'How much money can I make?',
        descr:
          'Again, this varies significantly based on the franchise. Many franchises disclose earnings of their current franchisees in Item 19 of the Franchise Disclosure Document.',
      },
      {
        id: 9,
        title:
          'What support will I receive from the franchisor when entering into a franchise agreement?',
        descr:
          'Franchisor support typically includes business planning, real estate, construction, marketing, technology, business operations, recruiting and training, and more. Be sure to understand what kind of support to expect from a specific franchisor before signing a contract.',
      },
      {
        id: 10,
        title: 'How do I select a site for my franchise?',
        descr:
          'You will receive assistance with the territory request and approval process. Once your territory has been approved, most franchisors have a system in place to help identify optimal real estate in your area and project management options for construction.',
      },
    ],
  }),
  methods: {
    toggle(item) {
      if (this.isOpen == item) {
        this.isOpen = undefined;
      } else {
        this.isOpen = item;
      }
    },
  },
  head() {
    const title = 'Franchise FAQs - Your Questions, Answered';
    const descr = 'Do you have a lot of questions related to franchising but not sure where to get the right answers? Take a look at the most common franchising FAQs.';
    const keywords = '';
    return {
      title,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ]
    }
  },
};
