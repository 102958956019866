//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from "vuex"
import pagesMixin from "~/components/mixins/pagesMixin.js"

import {
  MODAL_SIGN_UP_FRANCHISE_ID,
  USER_TYPE_FRANCHISEE_TITLE,
  USER_TYPE_FRANCHISOR_TITLE,
  REGISTRATION_STAGE_FORM,
  USER_TYPE_FRANCHISOR,
} from "~/store/constants"

export default {
  name: 'IndexPage',
  components: {
    LazySearchFranchises: () => import('~/components/SearchFranchises'),
    LazyBlogSubscribeConfirmation: () => import('~/components/blog/BlogSubscribeConfirmation'),
    LazyPromoHome: () => import('~/components/PromoHome'),
    LazyBlogHome: () => import('~/components/BlogHome'),
  },
  mixins: [
    pagesMixin,
  ],
  data() {
    return {
      showAddons: true,
    }
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
      articlesRecentArr: "blog/articlesRecentArr",
    }),
    isFranchiseeSignUp() {
      return this.$route.path === '/' && this.$route.query.signUp === USER_TYPE_FRANCHISEE_TITLE
    },
    isFranchisorSignUp() {
      return this.$route.path === '/' && this.$route.query.signUp === USER_TYPE_FRANCHISOR_TITLE
    },
    isContactUs() {
      return this.$route.path === '/' && this.$route.query.contactUs === 'true'
    },
    tagLineFranchisee() {
      if (this.pmIsSeeFranchisesPage()) {
        return 'See what franchises you qualify for?'
      }
      if (this.pmIsOwnFranchiseStepsPage()) {
        return 'What are the steps to being able to own your own franchise in 5 years?'
      }
      return 'Ready to see which franchises you qualify for. Get started with a few quick steps.'
    },
  },
  async mounted() {
    this.$nextTick(() => {
      if (process.client) {
        this.setHeroBackground();
      }

      if (this.isFranchiseeSignUp && !this.authenticated) {
        setTimeout(async () => {
          await this.showFranchiseeFastRegistration()
        }, 1000);
      }

      if (this.isContactUs) {
        setTimeout(() => {
          this.$store.dispatch('modals/contact/show', true)
        }, 1000);
      }

      if (this.isFranchisorSignUp && !this.authenticated) {
        setTimeout(async () => {
          await this.showFranchisorFormRegistration()
        }, 1000);
      }
    })
  },
  beforeDestroy() {
    this.$store.dispatch('nav/setNavOverlap', false)
  },
  methods: {
    setHeroBackground() {
      document.getElementById(
        "main-layout-bg"
      ).style.backgroundImage = ''
      document.getElementById("main-layout-bg").style.display = "none"
    },
    openEBook() {
      window.open("/documents/eBook-What_is_the_FDD.pdf", "_blank")
    },
    async showFranchiseeFastRegistration(item = null, showFdd = false) {
      this.$bvModal.show(MODAL_SIGN_UP_FRANCHISE_ID)
      await this.$store.commit('account/SET_SELECTED_FRANCHISE', item)
      await this.$store.commit('account/SET_SHOW_FDD', showFdd)
    },
    async showFranchisorFormRegistration() {
      this.$store.dispatch('registration/openModal', {
        stage: REGISTRATION_STAGE_FORM,
        userType: USER_TYPE_FRANCHISOR,
      })
    },
  },
  head() {
    const title = 'Franchises for Sale, Opportunities & Insights'
    const descr = 'Franchise123 is your ultimate hub for A to Z franchising from franchise opportunities, information, and exclusive insights to available franchises for sale.'
    const keywords = 'franchises for sale, Franchise Opportunities'
    return {
      title,
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: descr,
        },
        {
          name: 'keywords',
          hid: 'keywords',
          content: keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: descr,
        },
      ]
    }
  }
};
