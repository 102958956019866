//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from "@/components/buttons/BaseButton";
import MoneyFormat from 'vue-money-format';
import {mapGetters} from 'vuex';
import {MODAL_CHANGE_EMAIL_ID} from "@/store/constants";
import EditPhone from "@/components/form/accountFranchisor/EditPhone";
import InputCustom from "@/components/form/customField/InputCustom";
import EyeIcon from "@/components/icons/EyeIcon";
import InfoIcon from '@/assets/icons/InfoIcon.svg?inline';
import ModalChangeEmail from "~/components/modals/ModalChangeEmail";
import ApproveFranchiseVersionBtn from "~/components/account-franchisor/buttons/ApproveFranchiseVersionBtn";
import AccountFranchisorMembership from '~/components/account-franchisor/AccountFranchisorMembership.vue';
import AccountFranchisorLayout from "~/components/account-franchisor/AccountFranchisorLayout";

export default {
  name:'AccountFranchisorIndexPage',
  components: {
    BaseButton,
    EyeIcon,
    InputCustom,
    EditPhone,
    AccountFranchisorLayout,
    AccountFranchisorMembership,
    ApproveFranchiseVersionBtn,
    ModalChangeEmail,
    MoneyFormat,
    InfoIcon,
  },
  data: () => ({
    MODAL_CHANGE_EMAIL_ID,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      franchise: 'business-v2/franchise',
      ownerData: 'account-franchisor/ownerData',
      paymentUser: 'payment/user'
    }),
  },
  methods: {
    openFranchiseSite(url) {
      window.open(url, '_blank');
    },
    adaptValue(val) {
      return val || 'N/A';
    },
    firstLetter(word) {
      return word ? word.slice(0, 1) : '';
    },
    showFile(link) {
      this.$store.dispatch('iframe/openModal', link);
    },
    isNumber(value) {
      if (Number(value) === 0) {
        return true
      }

      return !Number(value)
    },
    isZeroRange(first, second) {
      return Number(first) === 0 && Number(second) === 0
    }
  },
  metaInfo() {
    return {
      title: `Edit: ${this.franchise.business_name}`,
    };
  },
};
