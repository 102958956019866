//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import Form from 'vform'
  import MoneyFormat from 'vue-money-format'
  import {mapGetters} from 'vuex'
  import BaseBtn from '~/components/buttons/BaseButton.vue'

  import {USER_TYPE_FRANCHISEE, USER_TYPE_FRANCHISOR,} from '~/store/constants'

  export default {
  components: {
    BaseBtn,
    MoneyFormat,
  },
  async asyncData({ $axios, route }) {
    let likes
    let franchise

    try {
      const franchiseResponse = await $axios.get(
          `/api/v1/business/${route.params.slug}/data/latest/`
      )
      franchise = franchiseResponse.data.data[0]
      const likeResponse = await $axios.get(`/api/v1/likes/get/${franchise.franchise_id}`)
      likes = likeResponse.data.result
    } catch (e) {
      console.log(e)
    }
    return { likes, franchise }
  },
  data() {
    return {
      hubspotMeetingLink: 'https://calendly.com/susan-617',
      likeBtnActive: true,
      SignedInMsg:
          'You are signed in as a Franchisor. If you are interested in buying this franchise please register a new account as a Franchisee. Please  use another email to sign up as a Franchisee.',
      modalSignedInMsgShow: false,
      form: new Form({
        name: '',
        email: '',
        phone: '',
        interested: '',
        message: '',
        agree: '',
        busy: false,
      }),
      modalShowStartCooperation: false,
      slug: 'test',
      sentModalShow: false,
      sentModalText:
          'You are signed in as a Franchisor. If you are interested in buying this franchise please register a new account as a Franchisee. Please  use another email to sign up as a Franchisee.',
      splitDateEstablished: '',
      splitDateSince: '',
      planBackground: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check',
      inProgressBusinessClientStep: 'franchisee-steps/inProgressBusinessClientStep',
    }),
    landingLink() {
      let url = ''
      if (
          this.franchise.public_website_business_permalink &&
          this.franchise.public_website_business_permalink.attribute_value_string
      ) {
        url = this.franchise.public_website_business_permalink.attribute_value_string
      }

      return url.includes(process.env.URL) ? url : ''
    },
  },
  async beforeDestroy() {
    await this.HubSpotEvent(
        { franchise_id: this.franchise.franchise_id },
        'open-page',
        'franchise-page'
    )
  },
  mounted() {
    this.splitStringEstablished()
    this.splitStringSince()
    this.setBusinessPlanClass()
  },
  methods: {
    setBusinessPlanClass() {
      this.planBackground = this.franchise.membership.attribute_value_string
          ? this.franchise.membership.attribute_value_string.toLowerCase()
          : ''
    },
    splitStringEstablished() {
      const stringToSplit = this.franchise.established.attribute_value_string
      const separator = '-'
      const arrayOfStrings = stringToSplit.split(separator)
      this.splitDateEstablished = arrayOfStrings[0]
    },
    splitStringSince() {
      const stringToSplit = this.franchise.franchising_since.attribute_value_string
      const separator = '-'
      const arrayOfStrings = stringToSplit.split(separator)
      this.splitDateSince = arrayOfStrings[0]
    },
    adaptValue(val) {
      return val || 'N/A'
    },
    async HubSpotEventSubscribe(email) {
      // Create the new request
      const xhr = new XMLHttpRequest()
      const url =
          'https://api.hsforms.com/submissions/v3/integration/submit/6241267/3669bc6d-e8d8-4d11-8060-e7812fc5c9b7'

      // Example request JSON:
      const data = {
        submittedAt: this.getNow,
        fields: [
          {
            name: 'email',
            value: email,
          },
        ],
        context: {
          hutk: this.getCookie('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission\
          pageUri: window.location.href,
          pageName: document.title,
        },
      }

      const FinalData = JSON.stringify(data)

      xhr.open('POST', url)

      // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      xhr.setRequestHeader('Content-Type', 'application/json')

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // Returns a 200 response if the submission is successful.
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          // Returns a 404 error if the formGuid isn't found
        }
      }

      // Sends the request
      xhr.send(FinalData)
    },
    getNow() {
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      this.timestamp = `${date} ${time}`
    },
    getCookie(name) {
      const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
      if (match) {
        return match[2]
      }
      return false
    },
    openFranchiseSite(url) {
      this.HubSpotEvent({ franchise_id: this.franchise.franchise_id }, 'website', 'franchise_page')
      window.open(url, '_blank')
    },
    HubSpotEvent(info, eventType, eventPage) {
      // disable before launch prod
      // return 0
      // check if user is non auth
      if (this.user) {
        // Create the new request
        const xhr = new XMLHttpRequest()
        const url =
            'https://api.hsforms.com/submissions/v3/integration/submit/6241267/2db3c6ad-d216-4543-a225-cd6dc65b1c0f'

        let franchiseId = 0
        if (info.franchise_id) {
          franchiseId = info.franchise_id
        }
        const franchiseUrl = `${document.location.protocol}//${document.location.host}/franchise-directory/industries/${this.franchise.slug_industry.attribute_value_string}/${this.franchise.slug}`

        // Example request JSON:
        const data = {
          submittedAt: this.getNow,
          fields: [
            {
              name: 'user_id',
              value: this.user.id,
            },
            {
              name: 'franchise_id',
              value: franchiseId,
            },
            {
              name: 'event_type',
              value: eventType,
            },
            {
              name: 'event_page',
              value: eventPage,
            },
            {
              name: 'email',
              value: this.user.email,
            },
            {
              name: 'firstname',
              value: this.user.first_name,
            },
            {
              name: 'lastname',
              value: this.user.last_name,
            },
            {
              name: 'franchise_title',
              value: this.franchise.business_name.attribute_value_string,
            },
            {
              name: 'franchise_url',
              value: franchiseUrl,
            },
            {
              name: 'franchise_logo',
              value: this.franchise.logo.large,
            },
            {
              name: 'timestamp',
              value: +new Date(),
            },
          ],
          context: {
            hutk: this.getCookie('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission\
            pageUri: franchiseUrl,
            pageName: this.franchise.business_name.attribute_value_string,
          },
        }

        const FinalData = JSON.stringify(data)

        xhr.open('POST', url)
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json')

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // alert(eventType)
            // alert(xhr.responseText)
            // Returns a 200 response if the submission is successful.
          } else if (xhr.readyState === 4 && xhr.status === 400) {
            // Returns a 400 error the submission is rejected.
          } else if (xhr.readyState === 4 && xhr.status === 403) {
            // Returns a 403 error if the portal isn't allowed to post submissions.
          } else if (xhr.readyState === 4 && xhr.status === 404) {
            // Returns a 404 error if the formGuid isn't found
          }
        };

        // Sends the request

        xhr.send(FinalData);
      }
    },
    showFDD(link) {
      if (this.authenticated) {
        try {
          this.user.isFranchisee
              ? this.$store.dispatch('iframe/openModal', link)
              : (this.sentModalShow = true)
        } catch (e) {
          console.log(e);
        }
      } else {
        this.$store.dispatch('registration/openModal')
      }
    },
    showFile(link) {
      if (this.authenticated) {
        this.$store.dispatch('iframe/openModal', link)
      } else {
        this.$store.dispatch('registration/openModal')
      }
    },
    async like() {
      if (!this.likeBtnActive) {
        return false
      }

      if (this.authenticated) {
        try {

          if (!this.user.isFranchisee) {
            this.modalSignedInMsgShow = true
            return true
          }

          this.likeBtnActive = false

          this.franchise.id = this.franchise.franchise_id
          const like = await this.$store.dispatch('business/toggleLike', this.franchise)

          if (like) {
            if (this.franchise.like) {
              this.likes.count++
            } else {
              this.likes.count--
            }
          }
        } catch (e) {
          console.log(e)
        }

        this.likeBtnActive = true

      } else {
        await this.$store.dispatch('registration/openModal')
      }
    },
    async showStartCooperationModal() {
      if (!this.authenticated) {
        await this.$store.dispatch('registration/openModal')
      } else if (this.user.user_type === USER_TYPE_FRANCHISEE) {
        if (!this.franchise.like) {
          await this.like()
        }
        if (!this.franchise.in_comparison) {
          await this.$store.dispatch('business/toggleComparison', this.franchise)
        }
        this.modalShowStartCooperation = true
      } else if (this.user.user_type === USER_TYPE_FRANCHISOR) {
        this.modalShowStartCooperation = false
        this.sentModalShow = true
      }
    },
    handleCloseModalStartCooperation() {
      this.modalShowStartCooperation = false
      if (this.user.user_type === USER_TYPE_FRANCHISEE) {
        this.$router.push(`/franchisee-steps?slug=${this.franchise.slug}`)
      }
    },
    /**
     * Contact us button click handler
     */
    async handleClickContactUs() {
      if (!this.authenticated || (this.authenticated && this.user.isFranchisor)) {
        await this.$store.dispatch('modals/contact/show')
      } else if (this.authenticated && this.user.isFranchisee) {
        await this.$store.dispatch('franchisee-steps/getOrCreateSteps', this.franchise.slug)
        await this.$router.push(
            `/franchisee-steps/chat?slug=${this.inProgressBusinessClientStep.slug}`
        )
      }
    },
  },
  head() {
    const title = this.franchise.seo_meta_title.attribute_value_string
    const keywords = this.franchise.seo_meta_keywords.attribute_value_string
    const description = this.franchise.seo_meta_description.attribute_value_string
    const businessName = this.franchise.business_name.attribute_value_string
    const shortDescription = this.franchise.short_descr.attribute_value_string

    return {
      title: title || businessName,
      link: [
        {
          rel: 'canonical',
          href: process.env.APP_URL + this.$route.path,
        }
      ],
      meta: [
        {
          hid: 'keywords',
          property: 'keywords',
          content: keywords,
        },
        {
          hid: 'description',
          name: 'description',
          property: 'description',
          content: description || shortDescription,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${title || businessName} | Franchise123`,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description || shortDescription,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: process.env.baseUrl,
        },
      ],
    }
  },
}
