//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClearIcon from "@/components/icons/ClearIcon";
import Form from "vform"
import Loader from "@/components/Loader";
import { MODAL_CONFIRM_CHANGE_EMAIL_ID } from "@/store/constants";

export default {
  components: {
    ClearIcon,
    Loader
  },
  data: () => ({
    MODAL_CONFIRM_CHANGE_EMAIL_ID,
    message: '',
    status: '',
    form: new Form()
  }),
  mounted() {
    this.$bvModal.show(MODAL_CONFIRM_CHANGE_EMAIL_ID)

    if (this.$route.query.user_id && this.$route.query.token) {
      this.confirm()
    } else {
      this.$router.push('/')
    }
  },
  methods: {
    async confirm() {
      try {
        const { data } = await this.form.get(
          `/api/email/change/${this.$route.query.user_id}/${this.$route.query.token}/confirm`
        )

        this.message = data.message
        this.status = data.status
      } catch (e) {
        console.log(e.response)
        if (e.hasOwnProperty('response')) {
          this.message = e.response.data.message
          this.status = e.response.data.status
        }
      }
    }
  }
}
